export const COUNTRY_VERIFICATION = "COUNTRY_VERIFICATION";

export const countryTabs = ["Country Validation", "Qualitative Summary"];

export const countryStatus = {
  notStarted: "Not Started",
  inProgress: "In Progress",
  finalized: "Finalized",
};

export const MONTH_DAY_RATE = 30.417;
