import { Grid, Divider, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import HeaderLink from "./HeaderLink";
import { LillyLogo } from "../icons";
import HeaderAvatar from "./HeaderAvatar";

export enum NavigatorTheme {
  Black,
  White,
}
const Header: FC<Record<string, never>> = () => {
  const { pathname } = useLocation();
  const [data] = useState({});
  const [headerTheme, setHeaderTheme] = useState(NavigatorTheme.White);

  const useBlackHeader = headerTheme === NavigatorTheme.Black;
  const containerStyle = useBlackHeader
    ? { backgroundColor: "#1C1C1C" }
    : {
        borderBottom: "1px solid #EEEEEE",
        backgroundColor: "#FFFFFF",
      };
  const themeFontColor = useBlackHeader ? "#FFF" : "#2D2D2D";

  useEffect(() => {
    const theme =
      pathname === "/" ||
      pathname.includes("/all-trials") ||
      pathname.includes("/trial-details")
        ? NavigatorTheme.White
        : NavigatorTheme.Black;
    setHeaderTheme(theme);
  }, [pathname]);
  return (
    <Container
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={containerStyle}
      role="banner"
      aria-label="Page Header"
    >
      <Grid item xs={12} sm={8} md={6}>
        <LinksContainer
          container
          direction="row"
          justifyContent="flex-start"
          wrap="nowrap"
          role="navigation"
          aria-label="Main Navigation"
        >
          <Link to="/">
            <LillyLogo
              style={{
                fontSize: "4rem",
                width: "1em",
                height: ".5em",
                color: useBlackHeader ? "#FFF" : "#D52B1E",
              }}
            />
          </Link>
          <VerticalDivider
            orientation="vertical"
            variant="middle"
            style={useBlackHeader ? { backgroundColor: "#82786F" } : {}}
          />
          <Workspace style={{ color: themeFontColor }}>
            PIC SE WORKSPACE
          </Workspace>
          <VerticalDivider orientation="vertical" variant="middle" />
          <HeaderMenuLink
            link="/"
            color="default"
            activePath={[
              "/",
              "/all-trials",
              "/trial-details",
              "/country-verification",
              "/site-selection",
            ]}
            style={{ color: themeFontColor }}
            analyticsOptions={{ action: "browse", labels: ["all trials"] }}
          >
            All Trials
          </HeaderMenuLink>
          <HeaderMenuLink
            link="/map"
            color="default"
            activePath={["/map"]}
            style={{ color: themeFontColor }}
            analyticsOptions={{ action: "browse", labels: ["map"] }}
          >
            MAPPING
          </HeaderMenuLink>
        </LinksContainer>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        container
        direction="row"
        justifyContent="flex-end"
      >
        <Box display="flex">
          <VerticalDivider
            orientation="vertical"
            variant="middle"
            style={useBlackHeader ? { backgroundColor: "#82786F" } : {}}
          />
          <HeaderAvatar data={data} fetchNotifications={() => void 0} />
        </Box>
      </Grid>
    </Container>
  );
};

const HeaderMenuLink = styled(HeaderLink)({
  textTransform: "uppercase",
  letterSpacing: "1px",
});

const Container = styled(Grid)(({ theme }) => ({
  padding: "0 5rem 0.1rem 5rem",
  zIndex: 5,
  [theme.breakpoints.down("xs")]: {
    alignItems: "stretch",
    padding: "0",
  },
}));

const LinksContainer = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}));

const Link = styled(RouterLink)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.common.black,
}));

const VerticalDivider = styled(Divider)({
  alignSelf: "center",
  height: "20px",
  margin: "0 10px",
});

const Workspace = styled("span")({
  fontWeight: 600,
  color: "#252525",
  fontSize: "1rem",
  letterSpacing: "0.03px",
  lineHeight: "1.5rem",
  padding: "0 10px",
});

export default Header;
