import React, { useEffect, useState } from "react";
import { Slider } from "antd";
import type { ColumnType } from "antd/es/table";

export const getColumnSliderProps = (
  key: string,
  valueRange: [number, number],
  afterChange: any
): ColumnType<any> => ({
  filterDropdown: () => {
    const [value, setValue] = useState<[number, number]>(valueRange);
    useEffect(() => {
      setValue(valueRange);
    }, [valueRange]);
    return (
      <div
        style={{ padding: "8px 18px", minWidth: "200px" }}
        onKeyDown={(e: any) => e.stopPropagation()}
      >
        <Slider
          range
          min={valueRange[0]}
          max={valueRange[1]}
          marks={{
            [valueRange[0]]: `${valueRange[0]}`,
            [valueRange[1]]: `${valueRange[1]}`,
          }}
          value={value}
          onAfterChange={(val: number | [number, number]) =>
            afterChange(key, val)
          }
          onChange={(v: any) => setValue(v)}
        />
      </div>
    );
  },
});
