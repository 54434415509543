interface StandardQuestion {
  id: number;
  questionId: number;
  title: string;
  component: any;
  response: any;
  category?: string;
}
interface questionCategoryProps{
  studySpecific: string;
  qualitative: string;
  quantitative: string;
}
interface questionStatusProps{
  skipped:string;
  answered: string;
  completed: string;
}

export const questionCategories: questionCategoryProps = {
    qualitative: 'Qualitative',
    quantitative: 'Quantitative',
    studySpecific: 'Study specific'
};
export const questionStatus:questionStatusProps = {
    skipped: 'skipped',
    answered: 'answered',
    completed: 'completed'
};

export const standardQuestions: Array<StandardQuestion> = [
    {
        id: 1,
        questionId: 1,
        title: 'Standard of Care',
        component: 'StandardOfCare',
        category: questionCategories.qualitative,
        response: {
            isYes: '',
            details: ''
        }
    },
    {
        id: 2,
        questionId: 2,
        title: 'High Patient Demand',
        component: 'HighPatientDemand',
        category: questionCategories.qualitative,
        response: {
            isYes: '',
            details: ''
        }
    },
    {
        id: 3,
        questionId: 3,
        title: 'Changes in Treatment Practice',
        component: 'ChangesInTreatmentPractice',
        category: questionCategories.qualitative,
        response: {
            isYes: '',
            details: ''
        }
    },
    {
        id: 4,
        questionId: 4,
        title: 'Regulatory Challenges',
        category: questionCategories.qualitative,
        component: 'RegulatoryChallenges',
        response: {
            isYes: '',
            details: ''
        }
    },
    {
        id: 5,
        questionId: 5,
        title: 'Logistical Issues',
        category: questionCategories.qualitative,
        component: 'LogisticalIssues',
        response: {
            isYes: '',
            details: ''
        }
    },
    {
        id: 6,
        questionId: 6,
        title: 'Capacity Constraints',
        category: questionCategories.qualitative,
        component: 'CapacityConstraints',
        response: {
            isYes: '',
            details: ''
        }
    },
    {
        id: 7,
        questionId: 7,
        title: 'Other Unique Aspects',
        category: questionCategories.qualitative,
        component: 'OtherUniqueAspects',
        response: {
            isYes: '',
            details: ''
        }
    },
    {
        id: 8,
        questionId: 8,
        title: 'Confirm Site Start-Up',
        category: questionCategories.quantitative,
        component: 'ConfirmSiteStartup',
        response: {
            isYes: '',
            noText: '',
            enrollmentReady: null,
            siteStartupTime: null
        }
    },
    {
        id: 9,
        questionId: 9,
        title: 'Confirm Number of Sites & Patients',
        category: questionCategories.quantitative,
        component: 'ConfirmNumberOfSitesAndPatients',
        response: {
            isYes: '',
            numSites: '',
            reasonOfSites: '',
            reasonOfPatitents: '',
            numPatients: '',
            minPatients: '',
            maxPatients: ''
        }
    },
    {
        id: 10,
        questionId: 10,
        title: 'Stretch Number of Sites & Patients',
        category: questionCategories.quantitative,
        component: 'StretchNumberOfPatients',
        response: {
            isYes: '',
            maxSites: '',
            maxFeasiblePatients: '',
            what: ''
        }
    },
    {
        id: 11,
        questionId: 11,
        title: 'Extended Enrollment Potential',
        category: questionCategories.quantitative,
        component: 'ExtendedEnrollmentPotential',
        response: {
            isYes: '',
            absMaxPatients: '',
            why: ''
        }
    }
];
