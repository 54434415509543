export const sipFilterOptions = [
    {
        title: 'Selected',
        value: 'sipSelected'
    },
    {
        title: 'Contacted',
        value: 'siteContacted'
    },
    {
        title: 'Pursued',
        value: 'sitePursued'
    }
];

export const joinedChoroplethLocationsQueryString = `query($field:[String], $state:String){
    choroplethLocationSearch(field:$field, state:$state){
        count,
        count2,
        joinedChoroplethLocations {
          geo_id
          county
          state
          country
          city
          name
          stateName
          category
          source
          value1
          value2
      }
        diseaseQuantiles{
          min,
          max,
          lower,
          upper,
        }
        demoQuantiles{
          min,
          max,
          lower,
          upper,
        }
        diseaseQuantiles2 {
          min
          max
          lower
          upper
        } 
    }
  }`;

export const globalChoroplethLocationQueryString = `query($input: GlobalChoroplethLocationInput!){
    globalChoroplethLocation(input: $input){
        message,
        count,
        choroplethLocations {
            country
            maturityCategory
            maturityCategoryDescription
            prevalenceSource
            prevalenceFloat
            prevalenceLinkages
            prevalenceSource2
            prevalenceFloat2
            prevalenceLinkages2
            comments
        }
        diseaseQuantiles {
            min,
            max,
            lower,
            median,
            upper,
        }
        diseaseQuantiles2 {
          min,
          max,
          lower,
          median,
          upper,
      }
    }
}`;
export const globalChoroplethLocationQueryStringWithQuantitles4 = `query($input: GlobalChoroplethLocationInput!){
  globalChoroplethLocation(input: $input){
      message,
      count,
      diseaseQuantiles { min lower median upper max}
      choroplethLocations {
          country
          maturityCategory
          maturityCategoryDescription
          prevalenceSource
          prevalenceFloat
          comments
      }
  }
}`;
