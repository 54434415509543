import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { ColorScheme, MapProps } from "../../../../@types/Map";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

import US_COUNTIES_JSON from "../../../../utils/us-counties.json";
import WORLD_JSON from "../../../../utils/world.geo.json";

import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {
  countryNameCorrection,
  generateMarkers,
  getDiseaseHtml,
  getOusDiseaseHtml,
  getTertileRange,
  unmatchedCountryNames,
} from "./utils";
import { isStateLevel } from "../../MapPage";
import ColorSchemes, { getColor, getColorQuantiles4 } from "./ColorSchemes";
import BinaryLegend from "./BinaryLegend";
import DemographicLegend from "./DemographicLegend";
import DiseaseLegend from "./DiseaseLegend";
import DctTypesLegend from "./DctTypesLegend";
import BinaryLegendQuantitle4 from "./BinaryLegendQuantitle4";
import StatusLegend from "./StatusLegend";
import {
  GLOBAL_MAP_CENTER,
  GLOBAL_ZOOM,
  MAP_CENTER,
  MAP_ZOOM,
  SHOW_TIP_NUMMBER,
  STATE_ZOOM,
  dctColor,
  dctTypeQuantiles,
} from "./mapConstants";

import { useMarkers } from "./useMarkers";

const Map: FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  filters,
  getCurrentBounds,
  choroplethDataSource,
  demographicsKey,
  demographicsText,
  diseaseKey,
  diseaseQuantiles,
  diseaseQuantiles2,
  demoQuantiles,
  dctData,
  pastTrialData,
  upcomingTrialData,
  mapColor,
  mapColorArr,
  updateZoom,
  locationList,
  ...options
}) => {
  const confirmConfig = {
    icon: <ExclamationCircleOutlined />,
    title:
      "The option you have chosen has too many results to display. Please clear your filter and zoom in a specific region or select a state for see results.",
    okText: "OK",
  };

  const ref = useRef<any>(null);
  const [colorScheme, setColorScheme] = useState<ColorScheme>({
    name: "",
    colors: [],
  });
  const [map, setMap] = useState<google.maps.Map>();
  const [dataLayer, setDataLayer] = useState<any>();
  const [dctClusters, setDctClusters] = useState<any>({});
  const [mapListeners, setMapListeners] = useState<any>();
  const [isChoropleth, setIsChoropleth] = useState<boolean | undefined>(false);
  const [selectedInfoWindow, setSelectedInfoWindow] =
    useState<google.maps.InfoWindow>(new google.maps.InfoWindow());
  const [mapScope, setMapScope] = useState<string>("US");

  const { allMarkers } = useMarkers(locationList, map);

  useEffect(() => {
    setColorScheme({
      name: mapColor,
      colors: mapColorArr,
    });
  }, [mapColor]);

  useEffect(() => {
    if (!map) return;
    map.setZoom(filters.mapScope === "OUS" ? GLOBAL_ZOOM : MAP_ZOOM);
    setMapScope(filters.mapScope);
  }, [filters.mapScope, map]);

  useEffect(() => {
    if (!map || filters.mapScope === "OUS") return;
    if (filters.state) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          address: filters.state,
          componentRestrictions: {
            country: filters.state === "Puerto Rico" ? "PR" : "US",
          },
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            map.setCenter(results[0].geometry.location);
            map.setZoom(STATE_ZOOM);
          }
        }
      );
    } else {
      map.setCenter(MAP_CENTER);
      map.setZoom(MAP_ZOOM);
    }
  }, [filters.state, map]);

  useEffect(() => {
    if (!map || filters.mapScope === "US") return;
    if (filters.country) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { address: filters.country },
        (results: any, status: any) => {
          if (status == google.maps.GeocoderStatus.OK) {
            map.setCenter(results[0].geometry.location);
            map.fitBounds(results[0].geometry.viewport);
          }
        }
      );
    } else {
      map.setCenter(GLOBAL_MAP_CENTER);
      map.setZoom(GLOBAL_ZOOM);
    }
  }, [filters.country, map]);

  useEffect(() => {
    if (
      filters.disease.length > 0 ||
      Boolean(filters.demographics) ||
      filters.prevalenceDisease.length > 0 ||
      filters.dctSubCapability
    ) {
      setIsChoropleth(true);
    } else {
      setIsChoropleth(false);
    }
  }, [filters]);

  const showDialog = (map: google.maps.Map) => {
    const currentZoom = map.getZoom();
    if (currentZoom === MAP_ZOOM) {
      if (
        pastTrialData?.length > SHOW_TIP_NUMMBER ||
        dctData?.length > SHOW_TIP_NUMMBER
      ) {
        Modal.warning(confirmConfig);
      }
    }
  };

  useEffect(() => {
    if (!map) return;
    showDialog(map);

    // eslint-disable-next-line consistent-return
    return () => {
      Modal.destroyAll();
    };
  }, [map, dctData, pastTrialData]);

  useEffect(() => {
    if (ref.current && !map) {
      const updatedMap = new window.google.maps.Map(ref.current, {
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      });

      updatedMap.setOptions(options);

      // -------choropleth map starts-------
      const layer = new google.maps.Data();
      if (filters.mapScope === "US") {
        layer.addGeoJson(US_COUNTIES_JSON);
      } else if (filters.mapScope === "OUS") {
        layer.addGeoJson(WORLD_JSON);
      }

      if (!choroplethDataSource || choroplethDataSource?.length === 0) return;
      layer.setStyle(() => ({
        visible: false,
        clickable: true,
        fillOpacity: 0.8,
        strokeColor: "#b3b3b3",
        strokeWeight: 1,
        zIndex: 999,
      }));
      layer.setMap(updatedMap);
      setDataLayer(layer);
      // -------choropleth map ends-------
      setMap(updatedMap);
    }

    if (ref.current && map) {
      // ---------cluster markers starts----------
      if (dctData.length === 0) {
        const tmpDctClusters = dctClusters;
        Object.entries(tmpDctClusters)?.forEach((pair: any) => {
          const cluster = pair[1];
          if (cluster) cluster.clearMarkers();
        });
        setDctClusters(tmpDctClusters);
      }

      if (dctData.length > 0) {
        // initiate dctClusters
        dctData?.forEach((dct: string, index: number) => {
          const dctName = filters.dctElement[index];
          if (dctClusters[dctName]) return;

          setDctClusters(
            Object.assign(dctClusters, {
              [dctName]: new MarkerClusterer({
                map,
                onClusterClick: () => void 0,
                renderer: {
                  render({ count, position }) {
                    // create svg url with fill color
                    const svg = window.btoa(`
                      <svg fill="${dctColor[index]}" strokeWidth="1" stroke="rgba(0,0,0,0.9)"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                      <circle cx="100" cy="100" opacity=".8" r="50" />
                      </svg>
                    `);
                    // create marker using svg icon
                    return new google.maps.Marker({
                      position,
                      icon: {
                        url: `data:image/svg+xml;base64,${svg}`,
                        scaledSize: new google.maps.Size(75, 75),
                      },
                      label: {
                        text: String(count),
                        color: "rgba(0,0,0,0.9)",
                        fontSize: "12px",
                        fontWeight: "bold",
                      },
                      // adjust zIndex to be above other markers
                      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                    });
                  },
                },
              }),
            })
          );
        });

        Object.entries(dctClusters)?.forEach((pair: any, idx: number) => {
          const cluster = pair[1];
          if (cluster) {
            cluster.clearMarkers();
            const dctMarkers = generateMarkers(
              dctData[idx],
              "dct",
              dctColor[idx],
              map
            );
            if (dctMarkers && dctMarkers.length > 0)
              cluster.addMarkers(dctMarkers);
          }
        });
      }

      // ---------cluster markers ends----------

      if (filters.mapScope === "US") {
        dataLayer.addGeoJson(US_COUNTIES_JSON);
      } else if (filters.mapScope === "OUS") {
        dataLayer.addGeoJson(WORLD_JSON);
      }

      dataLayer?.forEach((feature: any) => {
        if (!choroplethDataSource || choroplethDataSource?.length === 0) {
          dataLayer.overrideStyle(feature, {
            visible: false,
          });
          return;
        }
        // reset color
        dataLayer.overrideStyle(feature, {
          visible: isChoropleth,
          fillColor: "transparent",
        });

        let targetFeat: any;
        const GEO_ID = feature.getProperty("GEOID");
        const STATE_NAME = feature.getProperty("STATE_NAME");
        let COUNTRY_NAME = feature.getProperty("name");
        // country name correction, to match world.geo.json
        if (unmatchedCountryNames.includes(COUNTRY_NAME)) {
          COUNTRY_NAME = countryNameCorrection(COUNTRY_NAME);
        }

        const countyFeat = choroplethDataSource?.find(
          (e: any) => e?.geo_id?.slice(-5) === GEO_ID
        );
        const stateFeat = choroplethDataSource?.find(
          (e: any) => e?.stateName === STATE_NAME
        );
        const countryFeat = choroplethDataSource.find(
          (e: any) => e?.country?.toUpperCase() === COUNTRY_NAME?.toUpperCase()
        );

        if (filters.mapScope === "US") targetFeat = countyFeat || stateFeat;
        else targetFeat = countryFeat;

        if (targetFeat) {
          let values: any;
          if (filters.mapScope === "US") {
            if (filters.disease.length === 2) {
              values = {
                y: targetFeat["value1"],
                x: targetFeat["value2"],
              };
            } else {
              const diseaseKey = filters.disease.map((d: any) => d.value);
              values = {
                x: targetFeat[
                  demographicsKey?.slice(-1) === "r"
                    ? `${demographicsKey.slice(0, -1)}n`
                    : demographicsKey
                ],
                y: isStateLevel(diseaseKey[0])
                  ? targetFeat["Percentage"]
                  : targetFeat[diseaseKey[0]],
              };
            }
          }
          if (filters.mapScope === "OUS") {
            if (
              filters.prevalenceDisease &&
              filters.prevalenceDisease.length > 0 &&
              !filters.dctSubCapability
            ) {
              if (filters.prevalenceDisease.length === 2) {
                // 2 indications
                values = {
                  x: targetFeat["prevalenceFloat"],
                  y: targetFeat["prevalenceFloat2"],
                };
              }
              if (filters.prevalenceDisease.length === 1) {
                // 1 indication
                values = {
                  y: targetFeat["prevalenceFloat"],
                };
              }
            }
            if (
              filters.dctSubCapability &&
              filters.prevalenceDisease?.length === 0
            ) {
              // only DCT
              const categoryValue: string =
                targetFeat["maturityCategory"]?.split(" ")[1];
              values = {
                y: 5 - Number(categoryValue), // to make type1 value higher than type4
              };
            }
            if (
              filters.dctSubCapability &&
              filters.prevalenceDisease.length === 1
            ) {
              // 1 indication + DCT
              const categoryValue: string =
                targetFeat["maturityCategory"]?.split(" ")[1];
              values = {
                y: 5 - Number(categoryValue),
                x: targetFeat["prevalenceFloat"],
              };
            }
          }

          if (filters.mapScope === "US" && values) {
            if (filters.disease && filters.disease.length === 2) {
              dataLayer.overrideStyle(feature, {
                visible: isChoropleth,
                fillColor:
                  getColor(colorScheme.colors, values, {
                    xQuantile: diseaseQuantiles2,
                    yQuantile: diseaseQuantiles,
                  }) || "transparent",
              });
            } else {
              dataLayer.overrideStyle(feature, {
                visible: isChoropleth,
                fillColor:
                  getColor(colorScheme.colors, values, {
                    xQuantile: demoQuantiles,
                    yQuantile: diseaseQuantiles,
                  }) || "transparent",
              });
            }
          }
          if (filters.mapScope === "OUS" && values) {
            if (
              filters.prevalenceDisease &&
              filters.prevalenceDisease.length === 2
            ) {
              // 2 indications, 4 quantiles
              dataLayer.overrideStyle(feature, {
                visible: isChoropleth,
                fillColor:
                  getColorQuantiles4(values, {
                    xQuantile: diseaseQuantiles,
                    yQuantile: diseaseQuantiles2,
                  }) || "transparent",
              });
            }
            if (
              filters.prevalenceDisease &&
              filters.prevalenceDisease.length === 1
            ) {
              // 1 indications, 4 quantiles
              dataLayer.overrideStyle(feature, {
                visible: isChoropleth,
                fillColor:
                  getColorQuantiles4(values, {
                    yQuantile: diseaseQuantiles, // map to yAxis color
                  }) || "transparent",
              });
            }
            if (
              filters.prevalenceDisease.length === 0 &&
              filters.dctSubCapability
            ) {
              // only dct, 4 quantiles
              dataLayer.overrideStyle(feature, {
                visible: isChoropleth,
                fillColor:
                  getColorQuantiles4(values, { yQuantile: dctTypeQuantiles }) ||
                  "transparent",
              });
            }
            if (
              filters.prevalenceDisease.length === 1 &&
              filters.dctSubCapability
            ) {
              // 1 inidcation + dct, 4 quantiles
              dataLayer.overrideStyle(feature, {
                visible: isChoropleth,
                fillColor:
                  getColorQuantiles4(values, {
                    xQuantile: diseaseQuantiles,
                    yQuantile: dctTypeQuantiles,
                  }) || "transparent",
              });
            }
          }
        }
      });

      dataLayer.addListener("click", (event: any) => {
        const feat = event.feature;
        let targetFeat: any;
        const GEO_ID = feat.getProperty("GEOID");
        const STATE_NAME = feat.getProperty("STATE_NAME");
        let COUNTRY_NAME = feat.getProperty("name");
        const COUNTY = feat.getProperty("NAMELSAD");
        // country name correction
        if (unmatchedCountryNames.includes(COUNTRY_NAME)) {
          COUNTRY_NAME = countryNameCorrection(COUNTRY_NAME);
        }
        const countyFeat = choroplethDataSource?.find(
          (e: any) => e?.geo_id?.slice(-5) === GEO_ID
        );

        const stateFeat = choroplethDataSource?.find(
          (e: any) => e?.stateName === STATE_NAME
        );
        const countryFeat = choroplethDataSource?.find(
          (e: any) => e?.country?.toUpperCase() === COUNTRY_NAME?.toUpperCase()
        );

        if (filters.mapScope === "US") {
          if (countyFeat || stateFeat) {
            targetFeat = countyFeat || stateFeat;
            targetFeat.name = COUNTY;
          }
        } else {
          targetFeat = countryFeat;
        }

        if (!targetFeat) return;

        if (filters.mapScope === "OUS") {
          const htmlHead = "<div class='country-tooltip-wrapper'>";
          const countryHtml = getOusDiseaseHtml({ targetFeat, filters });

          const htmlFoot = "</div>";
          const tooltipHmtl = htmlHead + countryHtml + htmlFoot;
          selectedInfoWindow.setContent(tooltipHmtl);
        } else {
          const demoValue =
            targetFeat[
              demographicsKey?.slice(-1) === "r"
                ? `${demographicsKey.slice(0, -1)}n`
                : demographicsKey
            ];
          const diseaseValues = filters.disease?.map((d: any) => d.value) || [];

          if (
            (demoValue === null || demoValue === undefined) &&
            diseaseValues.length === 0
          )
            return;

          const demoTertileValues = getTertileRange(
            demoQuantiles,
            demoValue
          ).map((e) => Math.round(e));

          const htmlHead = "<div class='county-tooltip-wrapper'>";
          const htmlFoot = `<p class="notice">${
            (["Minnesota", "Kansas"].includes(targetFeat?.stateName) &&
              filters.therapeuticArea === "Oncology") ||
            isStateLevel(diseaseKey)
              ? "<B>* Estimated value based on state level rates/counts.</B>"
              : ""
          }</p></div>`;

          let html = `<p class='county-name'> ${`County Name: ${
            targetFeat?.name || "--"
          }`}</p> <p>State: ${targetFeat?.stateName}</p>`;
          const demographicsHtml =
            `<p>${demographicsText}: ${
              demoValue != null && demoValue !== undefined
                ? demoValue?.toLocaleString()
                : "N/A"
            } (${demoTertileValues[0]?.toLocaleString()}-${demoTertileValues[1]?.toLocaleString()})` +
            "</p>";

          let diseaseHtml = "";
          const isMultiple = filters.disease.length > 1;
          filters.disease?.forEach((d: any, i: number) => {
            let tertileValues: any;
            const isPercentage =
              d.value === "Percentage" || isStateLevel(d.value);
            let resDiseaseKey = "";
            if (!isMultiple) {
              resDiseaseKey =
                d.value === "Percentage" || isStateLevel(d.value)
                  ? "Percentage"
                  : d.value;
            } else {
              resDiseaseKey = `value${i + 1}`;
            }
            if (i === 0) {
              tertileValues = getTertileRange(
                diseaseQuantiles,
                targetFeat[resDiseaseKey]
              );
            } else if (i === 1 && diseaseQuantiles2) {
              tertileValues = getTertileRange(
                diseaseQuantiles2,
                targetFeat[resDiseaseKey]
              );
            }
            const rowString = getDiseaseHtml({
              title: d.title,
              value: targetFeat[resDiseaseKey],
              isPercentage,
              tertileValues,
            });
            diseaseHtml += rowString;
          });

          if (filters.demographics?.value) html += demographicsHtml;
          if (filters.disease) html += diseaseHtml;
          const tooltipHtml = htmlHead + html + htmlFoot;
          selectedInfoWindow.setContent(tooltipHtml);
        }
        selectedInfoWindow.setPosition(event.latLng);
        selectedInfoWindow.setOptions({
          pixelOffset: new google.maps.Size(0, -34),
        });
        selectedInfoWindow.open(map);

        setSelectedInfoWindow(selectedInfoWindow);
      });
      setDataLayer(dataLayer);
    }
  }, [
    ref,
    map,
    choroplethDataSource,
    diseaseQuantiles,
    diseaseQuantiles2,
    demoQuantiles,
    isChoropleth,
    colorScheme,
    dctData,
    filters.demographics,
    filters.disease,
    filters.therapeuticArea,
    upcomingTrialData,
    pastTrialData,
    locationList,
  ]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      map.setOptions({ draggableCursor: "url(./assests/hand.svg), move" });
      // to trigger map re-render when pathname changes back to "/map"
      google.maps.event.addListenerOnce(map, "idle", () => {
        map.setZoom(MAP_ZOOM);
      });
    }
  }, [map, onClick, onIdle]);

  const getRegion = (map: google.maps.Map) => {
    const bounds: any = map.getBounds();
    const NEPoint = bounds.getNorthEast();
    const SWPoint = bounds.getSouthWest();
    const south = SWPoint.lat();
    const west = SWPoint.lng();
    const north = NEPoint.lat();
    const east = NEPoint.lng();
    return [north, south, east, west];
  };

  const changeBounds = (map: google.maps.Map) => {
    const region = getRegion(map);
    const zoom = map.getZoom();
    updateZoom(zoom);
    getCurrentBounds(region);
  };
  const debouncedBoundsChange = useCallback(debounce(changeBounds, 3000), []);

  useEffect(() => {
    if (!map) return;

    if (!mapListeners) {
      map.addListener("drag", () => {
        debouncedBoundsChange(map);
      });

      map.addListener("zoom_changed", () => {
        debouncedBoundsChange(map);
        showDialog(map);
      });
      setMapListeners(true);
    }

    const colorschemes = document.getElementById(
      "color-schemes-wrapper"
    ) as HTMLElement;

    if (
      map?.controls[google.maps.ControlPosition.LEFT_TOP]?.getLength() === 0
    ) {
      map?.controls[google.maps.ControlPosition.LEFT_TOP]?.push(colorschemes);
    }

    const markerLegends = document.getElementById(
      "markers-legends-wrapper"
    ) as HTMLElement;
    const legend = document.getElementById("legends-wrapper") as HTMLElement;
    if (
      map?.controls[google.maps.ControlPosition.RIGHT_TOP]?.getLength() === 0
    ) {
      map?.controls[google.maps.ControlPosition.RIGHT_TOP]?.push(markerLegends);
    }

    if (
      map?.controls[google.maps.ControlPosition.RIGHT_BOTTOM]?.getLength() === 0
    ) {
      map?.controls[google.maps.ControlPosition.RIGHT_BOTTOM]?.push(legend);
    }

    const statusLegends = document.getElementById(
      "status-legends-wrapper"
    ) as HTMLElement;
    if (
      map?.controls[google.maps.ControlPosition.LEFT_BOTTOM]?.getLength() === 0
    ) {
      map?.controls[google.maps.ControlPosition.LEFT_BOTTOM]?.push(
        statusLegends
      );
    }
  }, [map, dctData, pastTrialData]);

  return (
    <>
      <div ref={ref} style={style} className="map-ref" />
      <div id="color-schemes-wrapper" data-testid="color-schemes-wrapper">
        {map && isChoropleth && filters.mapScope === "US" && <ColorSchemes />}
      </div>
      {map && ref.current ? (
        <div className="legends-panel">
          <div id="markers-legends-wrapper">
            {map &&
              (filters.dctElement ||
                filters.pastTrial ||
                filters.upcomingTrial) && (
                <div className="marker-legends">
                  {filters.dctElement &&
                    filters.dctElement?.map((dct: string, idx: number) => (
                      <div className="legend-item" key={dct}>
                        <i
                          className="icon dct-element"
                          style={{ background: dctColor[idx] }}
                        />{" "}
                        {dct}
                      </div>
                    ))}
                  {filters.pastTrial && (
                    <div className="legend-item">
                      <i className="icon past-trial" /> {filters.pastTrial}
                    </div>
                  )}
                  {filters.upcomingTrial && (
                    <div className="legend-item">
                      <i className="icon upcoming-trial" />{" "}
                      {filters.upcomingTrial}
                    </div>
                  )}
                </div>
              )}
          </div>
          <div id="legends-wrapper" data-testid="legends-wrapper">
            {map &&
            filters.mapScope === "US" &&
            ((isChoropleth && filters.demographics && filters.disease) ||
              filters.disease?.length === 2) ? (
              <BinaryLegend
                theme={colorScheme.name}
                title={{
                  x:
                    filters.disease?.length === 2
                      ? filters.disease[0].title
                      : "Population Demographics",
                  y:
                    filters.disease?.length === 2
                      ? filters.disease[1].title
                      : "Indication",
                }}
              />
            ) : null}
            {map &&
            filters.mapScope === "US" &&
            isChoropleth &&
            filters.demographics &&
            !filters.disease ? (
              <DemographicLegend theme={colorScheme.name} />
            ) : null}
            {map &&
            filters.mapScope === "US" &&
            isChoropleth &&
            !filters.demographics &&
            filters.disease?.length === 1 ? (
              <DiseaseLegend theme={colorScheme.name} title="Indication" />
            ) : null}

            {map &&
            filters.mapScope === "OUS" &&
            !filters.dctSubCapability &&
            filters.prevalenceDisease.length === 1 ? (
              <DctTypesLegend theme={colorScheme.name} title="Indication" />
            ) : null}
            {map &&
            filters.mapScope === "OUS" &&
            !filters.dctSubCapability &&
            filters.prevalenceDisease.length === 2 ? (
              <BinaryLegendQuantitle4
                theme={colorScheme.name}
                title={{
                  x: filters.prevalenceDisease[0].title,
                  y: filters.prevalenceDisease[1].title,
                }}
              />
            ) : null}
            {map &&
            filters.mapScope === "OUS" &&
            filters.dctSubCapability &&
            filters.prevalenceDisease.length === 0 ? (
              <DctTypesLegend theme={colorScheme.name} title="DCT Maturity" />
            ) : null}
            {map &&
            filters.mapScope === "OUS" &&
            filters.dctSubCapability &&
            filters.prevalenceDisease.length === 1 ? (
              <BinaryLegendQuantitle4
                theme={colorScheme.name}
                title={{ x: "Indication", y: "DCT Maturity" }}
              />
            ) : null}
          </div>
          <div id="status-legends-wrapper">
            {map && filters.mapScope === "US" && filters.upcomingTrial && (
              <StatusLegend />
            )}
          </div>
        </div>
      ) : null}

      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, map);
        }
      })}
    </>
  );
};

export default Map;
