import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';

const DiseaseLegend = (props: { theme: string, title: string }) => {
    const { theme, title } = props;

    return (
        <div className={`choropleth-legends disease ${theme}`}>
            <div className="y-axis low">
                <i className="x low" />
                <i className="x medium" />
                <i className="x high" />
            </div>
            <div className="x axis-label">
                <div className="axis-line">
                    <div className="x-axis-arrow axis" />
                    <CaretRightOutlined />
                </div>
                <span className="text">{title}</span>
            </div>
        </div>
    );
};
export default DiseaseLegend;
