import { createContext } from "react";

const AuthroizedContext = createContext({
  authenticated: true,
  setAuthenticated: (b: boolean) => {
    console.log(b);
  },
});

export default AuthroizedContext;
