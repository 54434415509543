import { ExclamationCircleOutlined } from "@ant-design/icons";
import { InfoOutlined } from "@mui/icons-material";
import { Autocomplete, Switch, TextField, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, Input, Modal, Radio } from "antd";
import { sortBy as _sortBy, uniq as _uniq } from "lodash";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { connect } from "react-redux";
import { Scrollbar } from "react-scrollbars-custom";
import {
  DctCapability,
  FiltersProps,
  PreviousFilterProps,
  ValueDescriptionPair,
} from "../../@types/MapPage";
import * as mapActions from "../../actions/map";
import { QueryByStringWithClient } from "../../api/apollo";
import { fetchByGraphqlString, graphqlStringMap } from "../../api/fetchByTypes";
import CustomErrorBar from "../../components/CustomErrorBar";
import {
  DISEASE_PREVALENCE_MAP,
  DISEASE_PREVALENCE_STATE_LEVEL,
  ONCOLOGY_STAGES_MAP,
} from "../../const/diseaseMapping";
import { STAR_SITE_FLAG } from "../../const/siteSelection";
import { STATES } from "../../const/statesMapping";
import { useJwtInfo } from "../../hooks/JwtInfo";
import { useUpdateUserFilters } from "../../hooks/useUpdateUserFilters";
import { getThemeColors } from "../../reducers/mapReducer";
import {
  capitalization,
  getBooleanValue,
  unique,
} from "../../utils/formatUtil";
import DataTable from "./components/DataTable";
import MapComponent from "./components/Map";
import { defaultThemeName } from "./components/Map/ColorSchemes";
import {
  globalChoroplethLocationQueryString,
  globalChoroplethLocationQueryStringWithQuantitles4,
  joinedChoroplethLocationsQueryString,
  sipFilterOptions,
} from "./constants";
import "./index.scss";

const { confirm } = Modal;
const USstates = Object.values(STATES);
const sucMsg = "Saved successfully!";
const DCTLimit = 3;
const indicationLimit = 2;

const getOncologyStages = () => {
  const oncologyStagesArr: any[] = [];
  Object.keys(ONCOLOGY_STAGES_MAP).map((stage: any) => {
    const stageObj: any = ONCOLOGY_STAGES_MAP[stage];
    return Object.entries(stageObj).forEach((item: any) => {
      oncologyStagesArr.push({
        title: item[1],
        value: item[0],
        stage,
      });
    });
  });
  return oncologyStagesArr;
};

export const isStateLevel = (disease: string) =>
  Object.keys(DISEASE_PREVALENCE_STATE_LEVEL).includes(disease);
export const uniqueSitesByLatLng = (arr: any[]) =>
  arr?.filter(
    (value: any, index: number, self: any) =>
      index ===
      self.findIndex((t: any) => t.lat === value.lat && t.lng === value.lng)
  );

export function showConfirm(onOk: () => void) {
  confirm({
    title: "Nickname exists already!",
    icon: <ExclamationCircleOutlined />,
    content: "Click OK will overwrite current scenario.",
    okText: "OK",
    okType: "danger",
    cancelText: "No",
    onOk() {
      onOk();
    },
  });
}

const initFilters: FiltersProps = {
  therapeuticArea: null,
  nickname: null,
  demographics: null,
  disease: [],
  prevalenceDisease: [],
  dctElement: [],
  pastTrial: null,
  upcomingTrial: null,
  state: null,
  sipFilter: [],
  mapScope: "US",
  dctCapability: null,
  dctSubCapability: null,
  country: null,
};
export const dctTypeQuantiles = {
  min: 1,
  lower: 2.25,
  median: 2.5,
  upper: 3.75,
  max: 4,
};

const oncologyStages = getOncologyStages();

const MapPage = (props: any) => {
  const { username } = useJwtInfo();
  const { updateUserFilter } = useUpdateUserFilters();
  const [diversityList, setDiversityList] = useState<any[]>([]);
  const [showTable, setShowTable] = useState(false);

  const [ousPreviousFilters, setOusPreviousFilters] = useState<
    PreviousFilterProps[]
  >([]);
  const [usPreviousFilters, setUsPreviousFilters] = useState<
    PreviousFilterProps[]
  >([]);

  const [TAList, setTAList] = useState([]);
  const [ousTAList, setOusTAList] = useState([]);
  const [pastStudyList, setPastStudyList] = useState([]);
  const [upcomingStudyList, setUpcomingStudyList] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);
  const [dctList, setDctList] = useState([]);
  const [capabilityCollection, setCapabilityCollection] = useState<
    DctCapability[]
  >([]);
  const [dctCapability, setDctCapability] = useState<string[]>([]);
  const [dctSubCapabilities, setDctSubCapabilities] = useState<string[]>([]);
  const [ousIndications, setOusIndications] = useState<any[]>([]);
  const [prevalenceTaAndIndications, setPrevalenceTaAndIndications] = useState(
    []
  );
  const [dctData, setDctData] = useState([]);
  const [pastTrialData, setPastTrialData] = useState([]);
  const [upcomingTrialData, setUpcomingTrialData] = useState([]);
  const [choroplethData, setChoroplethData] = useState([]);
  const [originGlobalData, setOriginGlobalData] = useState([]);
  const [countries, setCountries] = useState<string[]>([]);

  const [diseaseQuantiles, setDiseaseQuantiles] = useState({
    lower: 0,
    media: 0,
    upper: 0,
    min: 0,
    max: 0,
  });
  const [diseaseQuantiles2, setDiseaseQuantiles2] = useState({
    lower: 0,
    media: 0,
    upper: 0,
    min: 0,
    max: 0,
  });
  const [demoQuantiles, setDemoQuantiles] = useState({
    lower: 0,
    upper: 0,
    min: 0,
    max: 0,
  });
  const [loading, setLoading] = useState(false);
  const [sysError, setSysError] = useState("");
  const [filters, setFilters] = useReducer(
    (state: FiltersProps, newState: any) => ({ ...state, ...newState }),
    {
      ...props.filters,
    }
  );
  const [previousNickname, setPreviousNickname] = useState<string | null>(
    props.previousNickname
  );
  const [bounds, setBounds] = useState<number[]>([]);
  const [isFiltered, setIsFiltered] = useState<boolean | null>(null);
  const [showSwitch, setShowSwitch] = useState<boolean | null>(null); // true means it's from PISL
  const tableRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (tableRef.current) {
      const tableHeight = tableRef.current.offsetHeight;
      setHeight(tableHeight);
    }
  }, [showTable]);

  const onPreviousNicknameChange = (value: string | null) => {
    setPreviousNickname(value);
  };

  const onSelectChange = (value: string | null, key: string) => {
    if (key === "mapScope") {
      setFilters(initFilters);
      setPreviousNickname("");
      onClearFilters();
    }
    setFilters({
      [key]: value,
    });
    if (key === "therapeuticArea") {
      setFilters({
        disease: [],
        pastTrial: null,
        upcomingTrial: null,
        prevalenceDisease: [],
      });
      setShowSwitch(false);
      props.renderMap({
        ta: null,
        trial: null,
      });
      setUpcomingTrialData([]);
    } else if (key === "upcomingTrial") {
      setShowSwitch(false);
      props.renderMap({
        trial: null,
      });
      if (!value) {
        setFilters({
          sipFilter: [],
        });
        setUpcomingTrialData([]);
      }
    } else if (key === "pastTrial") {
      setShowSwitch(false);
      props.renderMap({
        ta: null,
      });
      if (!value) {
        setFilters({
          sipFilter: [],
        });
        setPastTrialData([]);
      }
    } else if (key === "disease") {
      if (value?.length === indicationLimit) {
        setFilters({
          demographics: null,
        });
      }
    } else if (key === "prevalenceDisease") {
      if (value?.length === 2) {
        setFilters({
          dctCapability: null,
          dctSubCapability: null,
        });
      }
    } else if (key === "dctCapability") {
      setFilters({
        dctSubCapability: null,
      });
    }
  };

  const onInputChange = (e: any, key: string) => {
    const value: string = e.target.value;
    setFilters({
      [key]: value,
    });
  };

  const toggleTable = () => {
    setShowTable(!showTable);
  };

  const onClearFilters = () => {
    setPreviousNickname("");
    setShowSwitch(null);
    props.renderMap({
      ta: null,
      trial: null,
      pislFilters: null,
    });
    // reset all data
    setFilters(Object.assign(initFilters, { mapScope: filters.mapScope }));
    setDctData([]);
    setUpcomingTrialData([]);
    setPastTrialData([]);
    setChoroplethData([]);
    setUpcomingStudyList([]);
    setPastStudyList([]);
  };

  const onSaveClick = () => {
    if (
      (filters.mapScope === "OUS" &&
        ousPreviousFilters
          .map((e: any) => e.nickName)
          .includes(filters.nickname)) ||
      (filters.mapScope === "US" &&
        usPreviousFilters
          .map((e: any) => e.nickName)
          .includes(filters.nickname))
    )
      showConfirm(onSaveFilters);
    else onSaveFilters();
  };

  const onSaveFilters = () => {
    const scenario = Object.assign(filters, {
      mapColor: props.themeColor,
      mapScope: filters.mapScope,
    });
    if (showSwitch) {
      scenario.isPisl = true;
      scenario.isFiltered = isFiltered;
      scenario.pislFilters = props.pislFilters;
    }
    setLoading(true);
    updateUserFilter({
      input: {
        userName: username,
        nickName: filters.nickname,
        filter: JSON.stringify(scenario),
      },
    })
      .then(() => {
        setSysError(sucMsg);
        // reload previous filters list
        getUserFilters();
        setPreviousNickname(filters.nickname);
      })
      .catch((error: any) => {
        setSysError(error.message || "There was an error, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getPastTrialData = () => {
    if (!filters.pastTrial) {
      setPastTrialData([]);
      return;
    }
    setLoading(true);
    let params: any = {
      study: filters.pastTrial,
      state: filters.state,
      country: filters.mapScope === "US" ? "United States" : filters.country,
    };
    if (bounds.length > 0) {
      params = Object.assign(params, { region: bounds });
    }

    QueryByStringWithClient(graphqlStringMap.fetchMappingData, params)
      .then((res: any) => {
        const result = res.data?.geoMappingSearch || [];
        const uniquePastTrialData: any = uniqueSitesByLatLng(result);
        setPastTrialData(uniquePastTrialData);
      })
      .catch((error: any) => {
        setSysError(error.message || "There was an error, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getUpcomingTrialData = () => {
    if (!filters.upcomingTrial) {
      setUpcomingTrialData([]);
      return;
    }
    setLoading(true);
    let params: any = {
      study: filters.upcomingTrial,
      state: filters.state,
      country: filters.mapScope === "US" ? "United States" : filters.country,
    };
    if (bounds.length > 0) {
      params = Object.assign(params, { region: bounds });
    }
    if (filters.sipFilter)
      Object.assign(params, { sipFilter: [filters.sipFilter.value] });

    QueryByStringWithClient(graphqlStringMap.fetchMappingData, params)
      .then((res: any) => {
        const result = res.data?.geoMappingSearch || [];
        const uniqueUpcomingTrialData: any = uniqueSitesByLatLng(result);
        setUpcomingTrialData(uniqueUpcomingTrialData);
      })
      .catch((error: any) => {
        setSysError(error.message || "There was an error, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getDiseaseList = () => {
    if (!filters.therapeuticArea) return;
    setLoading(true);
    QueryByStringWithClient(graphqlStringMap.fetchDiseaseList, {
      ta: filters.therapeuticArea,
    })
      .then((res: any) => {
        const result = res.data?.diseaseListFromTa;
        const diseaseArr = result.map((r: any) => ({
          title: DISEASE_PREVALENCE_MAP[r],
          value: r,
        }));
        setDiseaseList(diseaseArr);
      })
      .catch((error: any) => {
        setSysError(error.message || "There was an error, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFilterOptionsList = () => {
    setLoading(true);
    fetchByGraphqlString(graphqlStringMap.fetchGeoFilterOptions)
      .then((res: any) => {
        const dctElement = res.data?.dctDropdownList;
        const diversity = res.data?.diversitySearch
          ? JSON.parse(res.data?.diversitySearch)
          : {};
        const taList = res.data?.taList.sort();
        const diversityGroup = [];
        const diversityArr = Object.entries(diversity)?.map((item: any) => ({
          stage:
            item[0].slice(-1) === "r" ? "Population Rate" : "Population Count",
          title: item[1],
          value: item[0],
        }));
        const dctCapabilities = res.data?.dctCapabilities;
        const capabilityList = unique(
          dctCapabilities.map((c: any) => c.dctCapability)
        );
        const preTaAndIndication = res.data?.prevalenceTaAndIndications;
        const count = _sortBy(
          diversityArr?.filter((d) => d.stage === "Population Count"),
          "title"
        );
        const rate = _sortBy(
          diversityArr?.filter((d) => d.stage === "Population Rate"),
          "title"
        );
        diversityGroup.push(...count, ...rate);
        const ousTa = preTaAndIndication
          .map((t: any) => t.therapeuticArea)
          .sort();
        const countryList: string[] = res.data.pastTrialSiteLocationCountries;
        setCountries(countryList);
        setDiversityList(diversityGroup);
        setDctList(dctElement);
        setTAList(taList);
        setOusTAList(_uniq(ousTa));
        setCapabilityCollection(dctCapabilities);
        setDctCapability(capabilityList);
        setPrevalenceTaAndIndications(preTaAndIndication);
      })
      .catch((error: any) => {
        setSysError(error.message || "There was an error, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUpcomingTrialList = () => {
    if (!filters.therapeuticArea) {
      setUpcomingStudyList([]);
      return;
    }
    setLoading(true);
    QueryByStringWithClient(graphqlStringMap.fetcTrialsList, {
      ta: filters.therapeuticArea,
    })
      .then((res: any) => {
        const upcomingTrial = res.data?.upcomingDropdownSearch || [];
        const pastTrial = res.data?.pastDropdownSearch || [];
        setUpcomingStudyList(upcomingTrial);
        setPastStudyList(pastTrial);
      })
      .catch((error: any) => {
        setSysError(error.message || "There was an error, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getChoroplethData = () => {
    const demographics = filters.demographics?.value || "";
    const disease = filters.disease?.map((d: any) => d.value || "") || [];
    const diseaseColumn = filters.disease?.map((d: any) =>
      Object.keys(DISEASE_PREVALENCE_STATE_LEVEL).includes(d.value)
        ? "Percentage"
        : d.value
    );
    setLoading(true);

    if (filters.disease?.length === 2) {
      QueryByStringWithClient(joinedChoroplethLocationsQueryString, {
        field: [demographics, ...disease],
        state: filters.state,
      })
        .then((res: any) => {
          if (filters.disease && filters.disease.length === indicationLimit) {
            const joinedChoroplethLocations =
              res.data?.choroplethLocationSearch.joinedChoroplethLocations ||
              [];
            setChoroplethData(joinedChoroplethLocations);
          } else {
            const result =
              res.data?.choroplethLocationSearch?.choroplethLocations || [];
            setChoroplethData(result);
          }

          if (filters.disease.length > 0) {
            const diseaseQuant =
              res.data.choroplethLocationSearch?.diseaseQuantiles;
            setDiseaseQuantiles(diseaseQuant);
            if (res.data.choroplethLocationSearch?.diseaseQuantiles2) {
              const diseaseQuant2 =
                res.data.choroplethLocationSearch?.diseaseQuantiles2;
              setDiseaseQuantiles2(diseaseQuant2);
            }
          }
          if (filters.demographics) {
            const demoQuant = res.data.choroplethLocationSearch?.demoQuantiles;
            setDemoQuantiles(demoQuant);
          }
        })
        .catch((error: any) => {
          setSysError(error.message || "There was an error, please try again");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      QueryByStringWithClient(
        `query($field:[String], $state:String){
          choroplethLocationSearch(field:$field, state:$state){
              count,
              choroplethLocations{
                geo_id,
                county,
                state,
                name,
                stateName,
                ${
                  demographics.slice(-1) === "r"
                    ? `${demographics.slice(0, -1)}n`
                    : demographics
                },
                ${diseaseColumn && diseaseColumn[0] ? diseaseColumn[0] : ""}
              },
              diseaseQuantiles{
                min,
                max,
                lower,
                upper,
              }
              demoQuantiles{
                min,
                max,
                lower,
                upper,
              }
          }
        }`,
        {
          field:
            disease?.length === 0
              ? [demographics, ""]
              : [demographics, ...disease],
          state: filters.state,
        }
      )
        .then((res: any) => {
          const result =
            res.data?.choroplethLocationSearch?.choroplethLocations || [];
          setChoroplethData(result);
          if (filters.disease.length > 0) {
            const diseaseQuant =
              res.data.choroplethLocationSearch?.diseaseQuantiles;
            setDiseaseQuantiles(diseaseQuant);
          }
          if (filters.demographics) {
            const demoQuant = res.data.choroplethLocationSearch?.demoQuantiles;
            setDemoQuantiles(demoQuant);
          }
        })
        .catch((error: any) => {
          setSysError(error.message || "There was an error, please try again");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getGlobalChoroplethData = () => {
    const input: any = {};
    let queryString: string = globalChoroplethLocationQueryString;
    if (
      filters.prevalenceDisease?.length === 1 &&
      filters.dctCapability &&
      !filters.dctSubCapability
    )
      return;

    if (
      filters.prevalenceDisease &&
      filters.prevalenceDisease.length === 1 &&
      !filters.dctSubCapability
    ) {
      input.prevalenceIndication = filters.prevalenceDisease[0].value;
      input.prevalenceSource = filters.prevalenceDisease[0].source;
    }
    if (
      filters.prevalenceDisease &&
      filters.prevalenceDisease.length === 1 &&
      filters.dctSubCapability
    ) {
      input.prevalenceIndication = filters.prevalenceDisease[0].value;
      input.prevalenceSource = filters.prevalenceDisease[0].source;
      input.dctCapability = filters.dctCapability;
      input.dctSubCapability = filters.dctSubCapability;
      queryString = globalChoroplethLocationQueryStringWithQuantitles4;
    }
    if (filters.prevalenceDisease && filters.prevalenceDisease.length === 2) {
      input.prevalenceIndication = filters.prevalenceDisease[0].value;
      input.prevalenceIndication2 = filters.prevalenceDisease[1].value;
      input.prevalenceSource = filters.prevalenceDisease[0].source;
      input.prevalenceSource2 = filters.prevalenceDisease[1].source;
    }

    if (filters.prevalenceDisease?.length === 0 && filters.dctSubCapability) {
      input.dctCapability = filters.dctCapability;
      input.dctSubCapability = filters.dctSubCapability;
    }
    if (Object.keys(input).length === 0) return;

    setLoading(true);
    QueryByStringWithClient(queryString, { input })
      .then((res: any) => {
        const result =
          res.data?.globalChoroplethLocation?.choroplethLocations || [];
        setChoroplethData(result);
        setOriginGlobalData(result);
        if (filters.prevalenceDisease.length === 1) {
          const diseaseQuant =
            res.data.globalChoroplethLocation?.diseaseQuantiles;
          setDiseaseQuantiles(diseaseQuant);
        }
        if (filters.prevalenceDisease.length === 2) {
          const diseaseQuant2 =
            res.data.globalChoroplethLocation?.diseaseQuantiles2;
          setDiseaseQuantiles2(diseaseQuant2);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const getActiveFilters = () => {
      const filtersSource =
        filters.mapScope === "OUS" ? ousPreviousFilters : usPreviousFilters;
      const target = filtersSource.find(
        (f: PreviousFilterProps) => f.nickName === previousNickname
      );
      if (!target || !target.filter) return;
      const resultObj = JSON.parse(target.filter);
      const existingFilters = JSON.parse(JSON.stringify(resultObj));
      if (resultObj.isPisl) setShowSwitch(resultObj.isPisl);
      if (resultObj.isFiltered) setIsFiltered(resultObj.isFiltered);
      if (resultObj.pislFilters) {
        props.renderMap({
          pislFilters: resultObj.pislFilters,
          ta: resultObj.therapeuticArea,
          trial: resultObj.upcomingTrial,
        });
      }
      delete existingFilters.themeColor;
      delete existingFilters.isFiltered;
      delete existingFilters.isPisl;
      delete existingFilters.pislFilters;
      delete existingFilters.mapScope;
      setFilters(existingFilters);
      const colors = getThemeColors(resultObj.mapColor);
      props.renderMap({
        themeColor: resultObj.mapColor || defaultThemeName,
        colors: colors || getThemeColors(defaultThemeName),
      });
    };

    getActiveFilters();
  }, [previousNickname]);

  const getUserFilters = () => {
    setLoading(true);
    QueryByStringWithClient(graphqlStringMap.fetchUserFilter, {
      userName: username,
      nickName: null,
    })
      .then((res: any) => {
        const filters: PreviousFilterProps[] = res.data.userFilterSearch;
        // remove STAR_SITE nickname
        const starSitesIndex = filters.findIndex(
          (n: PreviousFilterProps) => n.nickName === STAR_SITE_FLAG
        );
        if (starSitesIndex > -1) filters.splice(starSitesIndex, 1);

        const ousFilters = filters.filter((f: PreviousFilterProps) => {
          const filterObj = f.filter && JSON.parse(f.filter);
          return filterObj.mapScope && filterObj.mapScope === "OUS";
        });
        const usFilters = filters.filter((f: PreviousFilterProps) => {
          const filterObj = f.filter && JSON.parse(f.filter);
          return !filterObj.mapScope || filterObj.mapScope === "US";
        });

        setOusPreviousFilters(ousFilters);
        setUsPreviousFilters(usFilters);
      })
      .catch((error: any) => {
        setSysError(error.message || "There was an error, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDCTData = () => {
    if (filters.dctElement.length === 0) {
      setDctData([]);
      return;
    }
    const params: any = {
      dctType: filters.dctElement,
      state: filters.state,
    };

    QueryByStringWithClient(graphqlStringMap.fetchDCTData, params).then(
      (res: any) => {
        const resp = res.data?.dctDataSearch || [];
        const uniqueDctData: any = resp.map((r: any) => uniqueSitesByLatLng(r));
        setDctData(uniqueDctData);
      }
    );
  };

  const toggleSwitch = (e: any) => {
    const val = e.target.checked;
    setIsFiltered(val);
  };

  const getAllPISLsites = () => {
    if (!props.trial && !filters.upcomingTrial) return;
    setLoading(true);
    let params = {
      study: props.trial || filters.upcomingTrial,
      geoMappingDatas: true,
      region: bounds,
      state: filters.state,
      country: "United States",
    };
    if (filters.sipFilter)
      Object.assign(params, { sipFilter: [filters.sipFilter.value] });

    if (isFiltered) {
      const tmpParams = Object.assign({}, props.pislFilters, params);
      const booleanKeys = ["lillyStudyExperience"];
      params = getBooleanValue(tmpParams, booleanKeys);
    }

    QueryByStringWithClient(graphqlStringMap.fetchSiteSelectionData, {
      ...params,
    })
      .then((res: any) => {
        const sites = res.data.siteDatasSearch?.geoMappingDatas;
        setUpcomingTrialData(sites);
      })
      .catch((error: any) => {
        setSysError(error.message || "There was an error, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    props.renderMap({
      previousNickname,
    });
  }, [previousNickname]);

  useEffect(() => {
    props.renderMap({
      filters,
    });
  }, [filters]);

  useEffect(() => {
    getFilterOptionsList();
  }, []);

  useEffect(() => {
    if (filters.mapScope === "US") getDiseaseList();
    getUpcomingTrialList();
  }, [filters.therapeuticArea, filters.mapScope]);

  useEffect(() => {
    if (
      !filters.upcomingTrial &&
      !filters.pastTrial &&
      !filters.demographics &&
      filters.disease.length === 0
    ) {
      setShowTable(false);
    }
    if (filters.demographics || filters.disease.length === 0) {
      setShowTable(false);
    }

    if (filters.upcomingTrial || filters.pastTrial) {
      setShowTable(false);
    }
  }, [filters]);

  useEffect(() => {
    getDCTData();
  }, [filters.dctElement, filters.state]);

  useEffect(() => {
    getPastTrialData();
  }, [filters.pastTrial, filters.state, bounds, filters.country]);

  useEffect(() => {
    if (showSwitch) {
      getAllPISLsites();
    }
    if (!showSwitch) {
      getUpcomingTrialData();
    }
  }, [
    filters.upcomingTrial,
    filters.state,
    showSwitch,
    bounds,
    filters.sipFilter,
    filters.country,
  ]);

  useEffect(() => {
    if (showSwitch) {
      getAllPISLsites();
    }
  }, [isFiltered]);

  useEffect(() => {
    if (filters.mapScope === "US") getChoroplethData();
  }, [filters.demographics, filters.disease, filters.state]);

  useEffect(() => {
    if (username) getUserFilters();
  }, [username]);

  useEffect(() => {
    // initate map page when from pisl
    if (props.pislFilters) {
      setShowSwitch(true);
      setIsFiltered(true);
      const obj: any = {
        therapeuticArea:
          props.ta.toLowerCase() === "neurology_psychology"
            ? "neuro_psychology"
            : capitalization(props.ta),
        upcomingTrial: props.trial,
        state: null,
      };
      // pre-select map state if only one state in pisl is selected
      if (props.pislFilters.state && props.pislFilters.state.length === 1) {
        obj.state = props.pislFilters.state[0];
      }
      setFilters(obj);
    }
  }, [props.pislFilters]);

  useEffect(() => {
    if (filters.mapScope !== "OUS") return;
    if (
      (filters.prevalenceDisease && filters.prevalenceDisease.length > 0) ||
      (filters.dctCapability && filters.dctSubCapability)
    )
      getGlobalChoroplethData();
  }, [
    filters.mapScope,
    filters.dctCapability,
    filters.dctSubCapability,
    filters.prevalenceDisease,
  ]);

  useEffect(() => {
    if (filters.dctCapability) {
      const subCap: string[] = unique(
        capabilityCollection
          .filter((c) => c.dctCapability === filters.dctCapability)
          .map((d) => d.dctSubCapability)
      );
      setDctSubCapabilities(subCap);
    }
  }, [filters.dctCapability]);

  useEffect(() => {
    if (filters.mapScope === "OUS") {
      const OUSIndications: any[] = prevalenceTaAndIndications
        .filter((data: any) => data.therapeuticArea === filters.therapeuticArea)
        .map((e: any) => ({
          title: e.indicationName,
          value: e.indicationValue,
          sources: e.prevalenceSources || [],
        }));
      const sourcedIndications: any = [];
      OUSIndications?.forEach((e: any) => {
        e.sources?.forEach((s: any) => {
          sourcedIndications.push({
            title: e.title,
            value: e.value,
            source: s,
          });
        });
      });
      setOusIndications(sourcedIndications);
    }
  }, [filters.mapScope, filters.therapeuticArea]);

  const dctSubCapabilityOptions = useMemo(
    () =>
      dctSubCapabilities.map((dctSubCapability) => {
        const dctSubCapabilityDescription = capabilityCollection.find(
          (dctCapability) => dctCapability.dctSubCapability === dctSubCapability
        )?.dctSubCapabilityDescription;
        return {
          value: dctSubCapability,
          description: dctSubCapabilityDescription,
        } as ValueDescriptionPair;
      }),
    [dctSubCapabilities, capabilityCollection]
  );

  return (
    <div className="map-page-container">
      <CustomErrorBar
        isSuccess={sysError === sucMsg}
        open={Boolean(sysError)}
        content={sysError}
        duration={1500}
        onClose={() => setSysError("")}
      />

      <div className="sidebar">
        <Scrollbar className="map-sidebar-scrollbar">
          <div className="title">Geo-Mapping</div>
          <div className="desc">
            View integration of data layers for disease prevalence, population
            diversity, DCT components and site availability to identify the most
            desirable geographic areas when targeting
            investigators/sites/patients.
          </div>
          <div className="selector-wrapper">
            <div className="selector-item">
              <Radio.Group
                onChange={(e: any) =>
                  onSelectChange(e.target.value, "mapScope")
                }
                value={filters.mapScope}
              >
                <Radio value="US">US Only</Radio>
                <Radio value="OUS">All Countries</Radio>
              </Radio.Group>
            </div>
            <div className="selector-item previous">
              <div className="label">SELECT PREVIOUSLY SAVED</div>
              <StyledAutocomplete
                disablePortal
                options={
                  filters.mapScope === "OUS"
                    ? ousPreviousFilters.map((e: any) => e.nickName)
                    : usPreviousFilters.map((e: any) => e.nickName)
                }
                value={previousNickname}
                onChange={(event: any, newValue: any) => {
                  onPreviousNicknameChange(newValue);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder="Search to select"
                    variant="standard"
                  />
                )}
              />
            </div>
            <div className="selector-item">
              <div className="label">THERAPEUTIC AREA</div>
              <StyledAutocomplete
                disablePortal
                options={filters.mapScope === "US" ? TAList : ousTAList}
                getOptionLabel={(option: any) =>
                  option?.toLowerCase() === "neuro_psychology"
                    ? "Neuroscience"
                    : option
                }
                value={filters.therapeuticArea}
                onChange={(event: any, newValue: any) => {
                  onSelectChange(newValue, "therapeuticArea");
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder="Filter by therapeutic area"
                    variant="standard"
                  />
                )}
              />
            </div>
            <div className="selector-item">
              <div className="label">INDICATION</div>
              {filters.mapScope === "OUS" ? (
                <StyledAutocomplete
                  multiple
                  disablePortal
                  getOptionLabel={(option: any) => option.source}
                  getOptionDisabled={(option: any) =>
                    filters.prevalenceDisease?.length >= indicationLimit &&
                    !filters.prevalenceDisease.includes(option)
                  }
                  options={ousIndications}
                  groupBy={(option: any) => option.title}
                  value={filters.prevalenceDisease || []}
                  onChange={(event: any, newValue: any) => {
                    onSelectChange(newValue, "prevalenceDisease");
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder="Select therapeutic area first (Max. of 2)"
                      variant="standard"
                    />
                  )}
                />
              ) : (
                <>
                  {filters.therapeuticArea !== "Oncology" ? (
                    <StyledAutocomplete
                      multiple
                      disablePortal
                      getOptionLabel={(option: any) => option.title}
                      getOptionDisabled={(option: any) =>
                        filters.disease?.length >= indicationLimit &&
                        !filters.disease.includes(option)
                      }
                      options={diseaseList}
                      value={filters.disease || []}
                      onChange={(event: any, newValue: any) => {
                        onSelectChange(newValue, "disease");
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          placeholder="Select therapeutic area first (Max. of 2)"
                          variant="standard"
                        />
                      )}
                    />
                  ) : (
                    <StyledAutocomplete
                      multiple
                      disablePortal
                      getOptionLabel={(option: any) => option.title}
                      getOptionDisabled={(option: any) =>
                        filters.disease?.length >= indicationLimit &&
                        !filters.disease.includes(option)
                      }
                      options={oncologyStages}
                      groupBy={(option: any) => option.stage}
                      value={filters.disease || []}
                      onChange={(event: any, newValue: any) => {
                        onSelectChange(newValue, "disease");
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          placeholder="Select therapeutic area first (Max. of 2)"
                          variant="standard"
                        />
                      )}
                    />
                  )}
                </>
              )}
            </div>
            {filters.mapScope === "OUS" ? (
              <>
                <div className="selector-item">
                  <div className="label">DCT CAPABILITY</div>
                  <StyledAutocomplete
                    disablePortal
                    options={dctCapability}
                    disabled={filters.prevalenceDisease?.length === 2}
                    value={filters.dctCapability}
                    onChange={(event: any, newValue: any) => {
                      onSelectChange(newValue, "dctCapability");
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Search to select"
                        variant="standard"
                      />
                    )}
                  />
                </div>
                <div className="selector-item">
                  <div className="label">DCT SUBCAPABILITY</div>
                  <StyledAutocomplete
                    disablePortal
                    options={dctSubCapabilities}
                    disabled={filters.prevalenceDisease?.length === 2}
                    value={filters.dctSubCapability}
                    onChange={(event, newValue: string) => {
                      onSelectChange(newValue, "dctSubCapability");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select DCT capability first"
                        variant="standard"
                      />
                    )}
                    renderOption={(props, option) => {
                      const description = dctSubCapabilityOptions.find(
                        (dctSubCapabilityOption) =>
                          dctSubCapabilityOption.value === option
                      )?.description;
                      return (
                        <li {...props}>
                          <span>{option}</span>
                          <Tooltip title={description || ""}>
                            <InfoOutlined
                              color="secondary"
                              style={{ verticalAlign: "middle" }}
                            />
                          </Tooltip>
                        </li>
                      );
                    }}
                  />
                </div>
              </>
            ) : null}
            <div className="selector-item">
              <div className="label">PAST TRIAL SITES</div>
              <StyledAutocomplete
                disablePortal
                options={pastStudyList}
                value={filters.pastTrial}
                onChange={(event: any, newValue: any) => {
                  onSelectChange(newValue, "pastTrial");
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder="Select therapeutic area first"
                    variant="standard"
                  />
                )}
              />
            </div>
            <div className="selector-item">
              <div className="label upcoming-trial-label">
                <span>UPCOMING TRIALS’ PISL</span>
                {showSwitch ? (
                  <div className="swith-wrapper">
                    <span className="filtered-sites">Filtered Sites</span>
                    <div>
                      <span>{isFiltered ? "Yes" : "No"}</span>
                      {isFiltered !== null ? (
                        <Switch
                          checked={isFiltered}
                          size="small"
                          onChange={(e: any) => toggleSwitch(e)}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
              <StyledAutocomplete
                disablePortal
                options={upcomingStudyList}
                value={filters.upcomingTrial}
                onChange={(event: any, newValue: any) => {
                  onSelectChange(newValue, "upcomingTrial");
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder="Select therapeutic area first"
                    variant="standard"
                  />
                )}
              />
            </div>
            {filters.mapScope === "US" ? (
              <>
                <div className="selector-item">
                  <div className="label">FILTER BY STATUS</div>
                  <StyledAutocomplete
                    disabled={!filters.upcomingTrial}
                    disablePortal
                    options={sipFilterOptions}
                    getOptionLabel={(option: any) => option.title}
                    value={filters.sipFilter?.value ? filters.sipFilter : null}
                    onChange={(event: any, newValue: any) => {
                      onSelectChange(newValue, "sipFilter");
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder={
                          !filters.sipFilter ? "Select a status to filter" : ""
                        }
                        variant="standard"
                      />
                    )}
                  />
                </div>
                <div className="selector-item">
                  <div className="label">PATIENT DEMOGRAPHICS</div>
                  <StyledAutocomplete
                    disablePortal
                    placeholder="Search to select"
                    options={diversityList}
                    getOptionLabel={(option: any) => option.title}
                    disabled={filters.disease?.length === indicationLimit}
                    groupBy={(option: any) => option.stage}
                    value={filters.demographics}
                    onChange={(event: any, newValue: any) => {
                      onSelectChange(newValue, "demographics");
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Search to select"
                        variant="standard"
                      />
                    )}
                  />
                </div>
                <div className="selector-item">
                  <div className="label">DCT ELEMENT</div>
                  <StyledAutocomplete
                    multiple
                    disablePortal
                    options={dctList}
                    value={filters.dctElement}
                    getOptionDisabled={(option: any) =>
                      filters.dctElement.length >= DCTLimit &&
                      !filters.dctElement.includes(option)
                    }
                    onChange={(event: any, newValue: any) => {
                      if (newValue.length <= DCTLimit)
                        onSelectChange(newValue, "dctElement");
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Search to select (Max. of 3)"
                        variant="standard"
                      />
                    )}
                  />
                </div>
                <div className="selector-item">
                  <div className="label">STATE</div>
                  <StyledAutocomplete
                    disablePortal
                    options={USstates.sort()}
                    value={filters.state}
                    onChange={(event: any, newValue: any) => {
                      onSelectChange(newValue, "state");
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Search to select"
                        variant="standard"
                      />
                    )}
                  />
                </div>
              </>
            ) : null}
            {filters.mapScope === "OUS" && (
              <div className="selector-item">
                <div className="label">COUNTRY</div>
                <StyledAutocomplete
                  disablePortal
                  options={countries.sort()}
                  value={filters.country}
                  onChange={(event: any, newValue: any) => {
                    onSelectChange(newValue, "country");
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder="Search to select"
                      variant="standard"
                    />
                  )}
                />
              </div>
            )}

            <div className="selector-item">
              <div className="label">NICKNAME</div>
              <Input
                placeholder="Type name here"
                value={filters.nickname}
                onChange={(e: any) => onInputChange(e, "nickname")}
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  border: "1px solid #D5D2CA",
                  borderRadius: "4px",
                  height: "2.75rem",
                }}
              />
            </div>
            <div className="button-wrapper">
              <Button className="clear-filters-btn" onClick={onClearFilters}>
                Clear Filters
              </Button>
              <Button
                type="primary"
                className="save-filters-btn"
                onClick={onSaveClick}
                disabled={!filters.nickname}
              >
                Save Filters
              </Button>
            </div>
          </div>
        </Scrollbar>
      </div>

      <div className="map-container">
        <MapComponent
          loading={loading}
          diseaseQuantiles={diseaseQuantiles}
          diseaseQuantiles2={diseaseQuantiles2}
          demoQuantiles={demoQuantiles}
          dctData={dctData}
          pastTrialData={pastTrialData}
          upcomingTrialData={upcomingTrialData}
          choroplethData={choroplethData}
          originGlobalData={originGlobalData}
          demographicsKey={filters.demographics?.value}
          demographicsText={filters.demographics?.title}
          diseaseKey={filters.disease?.map((d: any) => d.value) || []}
          filters={filters}
          toggleTable={toggleTable}
          showTable={showTable}
          getCurrentBounds={(b: any) => setBounds(b)}
          mapColorArr={props.colors}
          mapColor={props.themeColor}
          showSwitch={showSwitch}
        />
        <div className="table-section" ref={tableRef}>
          <div
            className="table-controller"
            style={{ position: "fixed", bottom: height + 8 }}
          >
            {(filters.pastTrial ||
              filters.upcomingTrial ||
              filters.demographics ||
              filters.disease.length > 0 ||
              filters.prevalenceDisease.length > 0 ||
              filters.dctSubCapability) && (
              <div className="btn-wrapper">
                <Button
                  type="primary"
                  onClick={toggleTable}
                  className="toggle-table-btn"
                >
                  {showTable ? "Hide" : "Show"} Table
                </Button>
              </div>
            )}
          </div>
          <div style={{ display: showTable ? "block" : "none" }}>
            <DataTable
              filters={filters}
              pastTrialData={pastTrialData}
              upcomingTrialData={upcomingTrialData}
              choroplethData={choroplethData}
              originGlobalData={originGlobalData}
              updateMap={(data: any) => setChoroplethData(data)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputBase-root": {
    paddingLeft: "8px",
    fontSize: "0.875rem",
    color: "rgba(0,0,0,.85)",
  },

  "& input::placeholder": {
    color: "rgb(191,191,191)",
  },

  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiFormControl-root.MuiTextField-root": {
    border: "1px solid #d9d9d9",
  },
}) as typeof Autocomplete; // fixes type inference issue with styled() https://github.com/mui/material-ui/issues/21727

const mapDispatchToProps = (dispatch: any) => ({
  renderMap: (val: any) => dispatch(mapActions.renderMap(val)),
});

const mapStateToProps = (state: any) => ({
  themeColor: state.mapReducer.themeColor,
  colors: state.mapReducer.colors,
  filters: state.mapReducer.filters,
  previousNickname: state.mapReducer.previousNickname,
  trial: state.mapReducer.trial,
  ta: state.mapReducer.ta,
  pislFilters: state.mapReducer.pislFilters,
});
export default connect(mapStateToProps, mapDispatchToProps)(MapPage);
