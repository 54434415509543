import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';

const colors = [
    '#c0c0c0',
    '#dfb0d6',
    '#be64ac',
    '#a64d78'
];

const DctTypesLegend = (props: { theme: string, title: string }) => {
    const { theme, title } = props;

    return (
        <div className={`choropleth-legends quarter disease ${theme}`}>
            <div className="y-axis low">
                <i className="x low" style={{ background: `${colors[0]}` }} />
                <i className="x medium" style={{ background: `${colors[1]}` }} />
                <i className="x high" style={{ background: `${colors[2]}` }} />
                <i className="x high" style={{ background: `${colors[3]}` }} />
            </div>
            <div className="x axis-label">
                <div className="axis-line">
                    <div className="x-axis-arrow axis" />
                    <CaretRightOutlined />
                </div>
                <span className="text">{title}</span>
            </div>
        </div>
    );
};
export default DctTypesLegend;
