export enum DctScoreColors {
    TYPE_1 = '#6dd400',
    TYPE_2 = '#f7b500',
    TYPE_3 = '#fa6400',
    TYPE_4 = '#e22e20'
}

export enum DctScores {
    TYPE_1 = 'Type 1',
    TYPE_2 = 'Type 2',
    TYPE_3 = 'Type 3',
    TYPE_4 = 'Type 4',
    NA = 'N/A'
}

export const dctScoreColorPairs = [
    {
        dctScore: DctScores.TYPE_1,
        color: DctScoreColors.TYPE_1
    },
    {
        dctScore: DctScores.TYPE_2,
        color: DctScoreColors.TYPE_2
    },
    {
        dctScore: DctScores.TYPE_3,
        color: DctScoreColors.TYPE_3
    },
    {
        dctScore: DctScores.TYPE_4,
        color: DctScoreColors.TYPE_4
    },
    {
        dctScore: DctScores.NA,
        color: null
    }
];

export const getColorForDctScore = (dctScore: string) => {
    const dctColor = dctScoreColorPairs.find((cellValue => cellValue.dctScore === dctScore));
    return dctColor?.color;
};
