import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Avatar, Badge, Button } from "@mui/material";
import { useJwtInfo } from "../../hooks/JwtInfo";

export interface HeaderAvatarProps {
  data: any;
  fetchNotifications: any;
}
const PREFIX = "HeaderAvatar";
const classes = {
  root: `${PREFIX}-root`,
};
const HeaderAvatarRoot = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const HeaderAvatar: React.FC<HeaderAvatarProps> = ({ data }) => {
  const { username } = useJwtInfo();
  const initials = username.match(/\b\w/g) || [];
  const myinitials = (
    (initials.shift() || "") + (initials.pop() || "")
  ).toUpperCase();

  const [anchorEl] = React.useState<null | HTMLElement>(null);
  const [isBadge, setBadge] = React.useState(false);
  // logic for showing the badge
  useEffect(() => {
    data?.notifications?.forEach((notification: any) => {
      if (notification.notificationType === "New") {
        setBadge(true);
      }
    });
  }, [data]);

  const notificationTriggered = {
    zIndex: 1000,
    backgroundColor: "white",
  };
  const avatarTheme = {
    backgroundColor: "#F94638",
    color: "#FFF",
  };

  return (
    <HeaderAvatarRoot className={classes.root}>
      <MenuButton
        style={anchorEl ? notificationTriggered : {}}
        className={anchorEl ? "hover-btn" : ""}
      >
        {isBadge ? (
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            variant="dot"
          >
            <UserLoggedIn style={avatarTheme}>{myinitials}</UserLoggedIn>
          </StyledBadge>
        ) : (
          <UserLoggedIn style={avatarTheme}>{myinitials}</UserLoggedIn>
        )}
      </MenuButton>
    </HeaderAvatarRoot>
  );
};

const MenuButton = styled(Button)({
  background: "transparent",
  position: "relative",
  top: "-2px",
  margin: "0 !important",
  minWidth: "50px !important",
});

const UserLoggedIn = styled(Avatar)({
  width: "2rem",
  height: "2rem",
  fontSize: "0.8rem",
  backgroundColor: "#444444",
  fontWeight: 500,
  position: "relative",
  top: "3px",
});

const StyledBadge = styled(Badge)(() => ({
  badge: {
    backgroundColor: "#D52B1E",
    color: "#D52B1E",
    width: "14px",
    height: "14px",
    borderRadius: "50%",
    border: "1px solid #FFFFFF",
    top: "4px",
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default HeaderAvatar;
