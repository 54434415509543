import React, { Suspense, lazy, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import fetchIntercept from 'fetch-intercept';
import AuthorizedContext from '../../provider/AuthorizedContextProvider';

export const HomePage = lazy(() => import('../../pages/HomePage'));
export const LandingPage = lazy(() => import('../../pages/LandingPage'));
export const CountryPage = lazy(() => import('../../pages/CountryPage'));
export const PreCountryPage = lazy(() => import('../../pages/PreCountryPage'));
export const SitePage = lazy(() => import('../../pages/SitePage'));
export const MapPage = lazy(() => import('../../pages/MapPage'));
export const UnauthorizedError = lazy(() => import('../../UnauthorizedError'));

const AppBody: React.FC = () => {
    const { authenticated, setAuthenticated } = useContext(AuthorizedContext);

    fetchIntercept.register({
        request(url, config) {
            // Modify the url or config here
            return [url, config];
        },
        requestError(error) {
            return Promise.reject(error);
        },
        response(response) {
            if (response.status === 403 || response.status === 401) {
                setAuthenticated(false);
            } else {
                setAuthenticated(true);
            }
            return response;
        },
        responseError(error) {
            return Promise.reject(error);
        }
    });

    return (
        <Container>
            <Suspense
                fallback={(
                    <Loader>
                        <CircularProgress style={{ color: '#d52b1e' }} />
                    </Loader>
                )}
            >
                {!authenticated ? (
                    <UnauthorizedError />
                ) : (
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/all-trials" element={<HomePage />} />
                        <Route
                            path="/trial-details/:studyAlias/:ta"
                            element={<LandingPage />}
                        />
                        <Route path="/country-verification" element={<CountryPage />} />
                        <Route
                            path="/country-verification/:studyAlias"
                            element={<CountryPage />}
                        />
                        <Route
                            path="/country-verification/:studyAlias/:countryId"
                            element={<CountryPage />}
                        />
                        <Route path="/pre-country-verification" element={<PreCountryPage />} />
                        <Route
                            path="/pre-country-verification/:studyAlias"
                            element={<PreCountryPage />}
                        />
                        <Route
                            path="/pre-country-verification/:studyAlias/:countryId"
                            element={<PreCountryPage />}
                        />
                        <Route path="/site-selection/:studyAlias/:ta" element={<SitePage />} />
                        <Route path="/map" element={<MapPage />} />
                    </Routes>
                )}
            </Suspense>
        </Container>
    );
};

const Loader = styled('div')({
    position: 'absolute',
    zIndex: 99999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    height: '100%',
    width: '100%'
});

const Container = styled('div')({
    display: 'flex',
    flex: '1',
    width: '100%',
    flexDirection: 'column',
    '& >div': {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        flex: 1
    }
});

export default AppBody;
