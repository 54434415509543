/*eslint-disable*/
import { isEqual } from 'lodash';
import moment from 'moment';

export const formatTime = (timestring?: string) => {
    if (timestring) {
        const date = new Date(timestring);
        const timeString = date.toLocaleTimeString().replace(/:\d+ /, ' ');
        const result = `${date.toDateString()} ${timeString}`;
        return result;
    }
    return '';
};


export const formatNumber = (value: number) => {
    let newValue = `${value}`;
    if (value >= 1000) {
        const suffixes = ['', 'K', 'M', 'B', 'T'];
        const suffixNum = Math.floor((`${value}`).length / 3);
        let shortValue:any;
        for (let precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
            let dotLessShortValue = (`${shortValue}`).replace(/[^a-zA-Z 0-9]+/g, '');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 !== 0) {
            shortValue = shortValue.toFixed(1);
        }
        newValue = shortValue + suffixes[suffixNum];
    }

    return newValue;
};

export const getRange = (start: any, stop: any, step: any) => Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
);

export const isObject = (data:any) => Object.prototype.toString.call(data) === '[object Object]';

export function getStringFromKebabCaseString(str: string) {
    return str.replace(/[A-Z]/g, item => ` ${item.toLowerCase()}`);
}
export const capitalize = (text: string |boolean| null) => {
    if (text == null) return;
    if (typeof text === 'boolean') {
        text = text ? 'True' : 'False';
    } else if (text.split(' ').length === 1) {
        text = text.replace(text[0], text[0].toUpperCase());
    } else {
        text = text.trim().split(' ').map(s => s.replace(s[0], s[0].toUpperCase())).join(' ');
    }
    return text; 
};

export const roundNumber = (num: number | string, n: number = 1) => {
    if (typeof num !== 'number') {
        num = Number(num);
    }
    if (!isNaN(num)) return Math.round(num * 10 ** n) / 10 ** n;
    return num;
};

export const booleanLabel = (value: boolean| null) => {
    if(value == null) return 'N/A';
    else return value ? 'Yes' : 'No'
}

export const getRoundedRate = (molecules:number, numerator:number) => Math.round((molecules / numerator) * 10000) / 100;



// the type of the key in an obj
export const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) => obj[key];


export const mobileCheck = () => {
    let check = false;

    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    || /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
    ) {
        check = true;
    } else {
    // check for ipad pro
        return navigator.maxTouchPoints
      && navigator.maxTouchPoints > 2
      && /MacIntel/.test(navigator.platform);
    }
    return check;
};

interface FiltersProps {
  lillyStudyExperience?: string;
  country?: string;
  state?: string[];
  enrollmentFeasibility?: number[];
  siteHealth?: number[];
  africanAmericanDiversity?: string[];
  hispanicDiversity?: string[];
  previousTrials?: string[];
  currentTrials?: string[];
  sipStatus?: string;
  contacted?: string;
  pursued?: string;
  pediatricStudy?: string[];
  siteType?: string[];
  isStarred?: string;
}

export const getAppliedFilter = (initFilters:any, filters:any, newFilters?: FiltersProps) => {
    const updatedfilters: any = {};
    Object.keys(initFilters).forEach((key: any) => {
        const initFilterValue = getKeyValue<keyof FiltersProps, FiltersProps>(
            key
        )(initFilters);
        const filterValue = getKeyValue<keyof FiltersProps, FiltersProps>(key)(
            newFilters || filters
        );
        if (!isEqual(initFilterValue, filterValue)) {
            updatedfilters[key] = filterValue;
        }
    });
    return updatedfilters;
};

export const sortTableColumns = (sorter:any) => {
	let keys: any[] = []
        const existingKeys = keys.map(k => k[0]);
        if (Object.prototype.toString.call(sorter) === '[object Array]') {
            sorter?.forEach((item: any, idx: number) => {
                const field: string = item.field;
                let orderStr: string = '';
                if (item.order) orderStr = (item.order === 'ascend' ? 'ASC' : 'DESC');

                if (!existingKeys.includes(field)) {
                    if (orderStr) keys.push([field, orderStr]);
                } else {
                    const idx = keys.findIndex((k: any, i: number) => k[0] === field);
                    if (orderStr) {
                        keys.splice(idx, 1, [field, orderStr]);
                    }
                    if (!orderStr) {
                        keys.splice(idx, 1);
                    }
                }
            });
            // unsort field
            keys.forEach((k: any, i: number) => {
                const idx = sorter.findIndex((s: any, i: number) => s.field === k[0]);
                if (idx < 0) {
                    keys.splice(i, 1);
                }
            });
        } else {
            // [object Object]
            const field: string = sorter.field;
            let orderStr: string = '';
            if (sorter.order) orderStr = (sorter.order === 'ascend' ? 'ASC' : 'DESC');

            if (orderStr) {
                keys = [[field, orderStr]];
            } else {
                keys = [];
            }
        }
		return keys
}


export const capitalization = (str:string) => str?.split(' ')
.map(
    (word: string) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()
)
.join(' ');


// export const transCountryData = (countryData:any) => {
//     let resp = countryData;
//     resp.enrollmentDuration = roundNumber(resp.enrollmentDuration);
//     resp.enrollmentDurationEst = roundNumber(resp.enrollmentDurationEst);
//     resp.enrollmentDurationEst = roundNumber(resp.enrollmentDurationEst);
//     // make it a yyyy-mm-dd string in case it's with hh:mm:ss
//     if (Number(resp.enrollmentReady)) {
//         resp.enrollmentReady = moment(Number(resp.enrollmentReady)).utc();
//     } else {
//         resp.enrollmentReady = moment(resp.enrollmentReady);
//     }
//     resp.enrollmentReady = resp.enrollmentReady
//         .format('YYYY-MM-DD')
//         .toString();

//     return resp;
// };


// remove keys from object if they are ''.
export const getBooleanValue = (obj: any, keys:string[]) => {
    keys.forEach((k:string) => {
        if (obj[k]) obj[k] = obj[k] === 'Yes';
        else delete obj[k];
    });
    return obj;
};

export const unique = (arr: any[]) => Array.from(new Set(arr));

