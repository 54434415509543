import { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";

export const useJwtInfo = () => {
  const [jwtInfo, setJwtInfo] = useState<AzureDetails | null>(null);
  const [username, setUsername] = useState<string>("");
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    let isMounted = true;
    if (accessToken) {
      try {
        const info = jwtDecode<AzureDetails>(accessToken);
        if (isMounted) {
          setUsername(info?.name.replace(/ - Network/g, "") || "");
          setJwtInfo(info);
        }
      } catch (error) {
        console.warn(error);
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);
  return { jwtInfo, username };
};
