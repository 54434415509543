import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { SITES_STATUS } from "../../../../const/mapping";
import { CustomMarker } from "../../../../@types/Map";

export const getTertileRange = (
  quantiles: {
    lower: number;
    upper: number;
    max: number;
  },
  value: any
) => {
  const { lower, upper, max } = quantiles;
  let tertileValues: number[] = [0, 0];
  if (value >= 0 && value <= lower) {
    tertileValues = [0, lower];
  }
  if (value > lower && value <= upper) {
    tertileValues = [lower, upper];
  }
  if (value > upper && value <= max) {
    tertileValues = [upper, max];
  }
  return tertileValues;
};

export const getInfoString = (
  info: any,
  type: string,
  sitesStatus?: string
) => {
  let className;
  if (sitesStatus === SITES_STATUS.selected) className = "star-icon";
  if (sitesStatus === SITES_STATUS.pursued) className = "triangle-icon";
  if (sitesStatus === SITES_STATUS.contacted) className = "square-icon";
  const infoStr = `<div class="info-wrapper">
${
  type === "dct"
    ? `<p class="name">Facility Name : ${info?.site_name || "N/A"}</p>
        <p> Address: ${info?.address}, ${info?.city}, ${info?.state},
        ${info?.zip}</p>`
    : `
        ${
          sitesStatus
            ? `<div class="site-status"><i class=${className}></i> ${sitesStatus} Sites</div>`
            : ""
        }
        <p class="name">Site Name: ${info?.name || "N/A"}</p>
        <p>Facility Golden ID: ${info?.facilityId || "N/A"}</p>
        <p> Site Address: ${info?.address}, ${info?.city}, ${info?.state},
        ${info?.zip} </p>`
}
</div>`;
  return infoStr;
};

export const getDiseaseHtml = (props: {
  title: string;
  value: string | number;
  isPercentage: boolean;
  tertileValues: any;
}) => {
  const { title, value, isPercentage, tertileValues } = props;
  const htmlString = `<p>${title}: ${
    value != null && value !== undefined ? value?.toLocaleString() : "N/A"
  }
${value && isPercentage ? "%" : ""} (${tertileValues[0]?.toLocaleString()}
${isPercentage ? "%" : ""}-${tertileValues[1]?.toLocaleString()}
${isPercentage ? "%" : ""})</p> `;

  return htmlString;
};

export const getOusDiseaseHtml = (props: { targetFeat: any; filters: any }) => {
  const { targetFeat, filters } = props;
  const countryEle = `<p class='country-name'> ${`${targetFeat?.country}`}</p>`;
  const dctEle = filters.dctSubCapability
    ? `<p> ${`DCT Score: ${targetFeat?.maturityCategory || "N/A"}`} </p>`
    : "";
  const diseaseEle =
    filters.prevalenceDisease.length > 0
      ? `<p> ${`${filters.prevalenceDisease[0]?.source}: ${
          targetFeat?.prevalenceFloat?.toLocaleString() || "N/A"
        }`} , sourced from ${targetFeat.prevalenceLinkages || "N/A"}</p>`
      : "";
  const diseaseEle2 =
    filters.prevalenceDisease.length > 1
      ? `<p> ${`${filters.prevalenceDisease[1]?.source}: ${
          targetFeat?.prevalenceFloat2?.toLocaleString() || "N/A"
        }`}, sourced from ${targetFeat.prevalenceLinkages2 || "N/A"}</p>`
      : "";

  return countryEle + dctEle + diseaseEle + diseaseEle2;
};

export const unmatchedCountryNames = [
  "Czechia",
  "South Korea",
  "United Kingdom",
  "Egypt",
  "Ghana",
  "Kenya*",
  "North Macedonia",
  "United States of America",
  "Zimbabwe",
];
export const countryNameCorrection = (country: string) => {
  let countryName;
  switch (country) {
    case "Czechia":
      countryName = "Czech";
      break;
    case "South Korea":
      countryName = "Korea";
      break;
    case "United Kingdom":
      countryName = "UK";
      break;
    case "Egypt":
      countryName = "Egypt*";
      break;
    case "Ghana":
      countryName = "Ghana*";
      break;
    case "Kenya":
      countryName = "Kenya*";
      break;
    case "North Macedonia":
      countryName = "Macedonia";
      break;
    case "United States of America":
      countryName = "United States";
      break;
    case "Zimbabwe":
      countryName = "Zimbabwe*";
      break;
    default:
      countryName = country;
  }

  return countryName;
};

export const generateMarkers = (
  data: any[],
  type: string,
  color: string,
  map: google.maps.Map
) => {
  const infoWindow = new google.maps.InfoWindow({
    content: "",
    disableAutoPan: true,
  });
  const markers = data?.map((dct: any) => {
    const position = { lat: Number(dct.lat), lng: Number(dct.lng) };
    const content = getInfoString(dct, type);
    const marker = new google.maps.Marker({
      icon: {
        path: faCircle.icon[4] as string,
        fillColor: color,
        fillOpacity: 1,
        anchor: new google.maps.Point(
          faCircle.icon[0] / 4, // width
          faCircle.icon[1] // height
        ),
        strokeWeight: 1,
        strokeColor: "rgba(0,0,0, 0.9)",
        scale: 0.02,
      },
      position,
    });
    // open info window when marker is clicked
    marker.addListener("click", () => {
      infoWindow.setContent(content);
      infoWindow.open(map, marker);
    });
    return marker;
  });
  return markers;
};

/**
 *  Note:
 *  This function is used to render the marker on the map when we select past or upcoming trail from the filter options
 */

export const addSingleMarkers = ({
  locationList,
  map,
}: {
  locationList: any;
  map: google.maps.Map;
}): CustomMarker[] => {
  if (locationList.length === 0) return [];
  const markers = locationList.map(
    (location: {
      lat: number;
      lng: number;
      color: string;
      type: string;
      facilityId: string;
    }) => {
      const marker: CustomMarker = new google.maps.Marker({
        position: location,
        map,
        icon: {
          path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 192m-25 0a25 25 0 1 0 50 0a25 25 0 1 0 -50 0",
          fillColor: location.color,
          fillOpacity: 1,
          anchor: new google.maps.Point(192, 192),
          strokeWeight: 1,
          strokeColor: "rgba(0,0,0, 0.9)",
          scale: 0.05,
        },
      });
      // these are added for upcoming requested feature to click on the marker and show the facility from the table
      marker.type = location.type;
      marker.facilityId = location.facilityId;
      return marker;
    }
  );
  return markers;
};
