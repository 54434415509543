import {
  InteractionStatus,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { throttle } from "lodash";
import { useEffect, useState } from "react";
import refreshTokens from "./refreshToken";

const REFRESH_TOKEN_TIME = 1000 * 60 * 5;

export function useFetchToken(
  instance: IPublicClientApplication,
  inProgress: InteractionStatus
) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handler = throttle(async () => {
      if (
        !(
          instance?.getAllAccounts().length &&
          inProgress === InteractionStatus.None
        )
      )
        return;

      await refreshTokens(instance.getAllAccounts());
      setLoading(false);
    }, REFRESH_TOKEN_TIME);

    handler();

    document.addEventListener("mousemove", handler);

    return () => {
      document.removeEventListener("mousemove", handler);
    };
  }, [instance, inProgress]);

  return { loading };
}
