import React, { useState, useEffect, useRef } from 'react';
import { CaretUpOutlined, CaretRightOutlined } from '@ant-design/icons';
import './quantitles4Legend.scss';

const BinaryLegendQuantitle4 = (props: { theme: string, title: { x: string, y: string} }) => {
    const { theme, title } = props;
    const [offset, setOffset] = useState(20);
    const labelRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (labelRef.current) {
            const labelHeight = labelRef.current.offsetHeight;
            setOffset(labelHeight);
        }
    }, [title.y]);

    return (
        <div className={`choropleth-legends quantitles ${theme}`}>
            <div className={`y-axis high ${theme}`}>
                <i className="x low" />
                <i className="x medium" />
                <i className="x upper" />
                <i className="x high" />
            </div>
            <div className={`y-axis upper ${theme}`}>
                <i className="x low" />
                <i className="x medium" />
                <i className="x upper" />
                <i className="x high" />
            </div>
            <div className={`y-axis medium ${theme}`}>
                <i className="x low" />
                <i className="x medium" />
                <i className="x upper" />
                <i className="x high" />
            </div>
            <div className={`y-axis low ${theme}`}>
                <i className="x low" />
                <i className="x medium" />
                <i className="x upper" />
                <i className="x high" />
            </div>
            <div className="y axis-label">
                <span className="text" ref={labelRef} style={{ left: `${-offset}px` }}>{title?.y}</span>
                <div className="axis-line">
                    <div className="y-axis-arrow axis" />
                    <CaretUpOutlined />
                </div>
            </div>
            <div className="x axis-label">
                <div className="axis-line">
                    <div className="x-axis-arrow axis" />
                    <CaretRightOutlined />
                </div>
                <span className="text">{title?.x}</span>
            </div>
        </div>
    );
};

export default BinaryLegendQuantitle4;
