import { Button, Checkbox } from "antd";
import { ColumnsType } from "antd/es/table";
import { getColumnSliderProps } from "./getColumnSliderProps";
import { getColorForDctScore } from "./dctColors";

export const dctTypeOptions = ["Type 1", "Type 2", "Type 3", "Type 4", "N/A"];

interface FilterDropdownProps {
  setSelectedKeys: (selectedKeys: string[]) => void;
  selectedKeys: string[];
  confirm: () => void;
  clearFilters: () => void;
}

interface GlobalTableCoulumnsProps {
  filters: any;
  sliderValue: {
    prevalenceFloat: [number, number];
    prevalenceFloat2: [number, number];
  };
  sliderRanges: {
    prevalenceFloatRange: [number, number];
    prevalenceFloatRange2: [number, number];
  };
  checkedValues?: string[];
  afterSliderChange?: any;
  onSelectChange?: any;
}

export default function useGlobalTableColumns({
  filters,
  sliderRanges,
  afterSliderChange,
  onSelectChange,
}: GlobalTableCoulumnsProps) {
  const tableCols: ColumnsType = [
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: (a: any, b: any) => {
        const countryA = a.country.toUpperCase();
        const countryB = b.country.toUpperCase();
        if (countryA < countryB) return -1;
        if (countryA > countryB) return 1;
        return 0;
      },
    },
  ];

  const dctCols: ColumnsType = [
    {
      title: "DCT Score",
      dataIndex: "maturityCategory",
      key: "maturityCategory",
      render: (value: string) => {
        const color = getColorForDctScore(value);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={
                color
                  ? {
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      backgroundColor: color,
                      marginRight: "8px",
                    }
                  : undefined
              }
            />
            {value}
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        const typeA = a.maturityCategory;
        const typeB = b.maturityCategory;
        if (typeA < typeB) return -1;
        if (typeA > typeB) return 1;
        return 0;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
      }: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            defaultValue={["Type 1", "Type 2", "Type 3", "Type 4", "N/A"]}
            onChange={(checkedValues: any) => setSelectedKeys(checkedValues)}
          >
            {dctTypeOptions.map((o) => (
              <div key={o} style={{ padding: 5 }}>
                <Checkbox value={o}>{o}</Checkbox>
              </div>
            ))}
          </Checkbox.Group>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "15px 0 5px 0",
            }}
          >
            <Button size="small" onClick={() => onSelectChange(selectedKeys)}>
              Apply
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: "Maturity Category Description",
      dataIndex: "maturityCategoryDescription",
      key: "maturityCategoryDescription",
      sorter: (a: any, b: any) =>
        a.maturityCategoryDescription - b.maturityCategoryDescription,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },
  ];
  const diseaseCol = {
    title: `${filters.prevalenceDisease[0]?.source}`,
    dataIndex: "prevalenceFloat",
    key: "prevalenceFloat",
    sorter: (a: any, b: any) => a.prevalenceFloat - b.prevalenceFloat,
    ...getColumnSliderProps(
      "prevalenceFloat",
      sliderRanges.prevalenceFloatRange,
      afterSliderChange
    ),
    render: (value: string | number) => <span>{value || "N/A"}</span>,
  };

  const diseaseCol2 = {
    title: `${filters.prevalenceDisease[1]?.source}`,
    dataIndex: "prevalenceFloat2",
    key: "prevalenceFloat2",
    sorter: (a: any, b: any) => a?.prevalenceFloat2 - b?.prevalenceFloat2,
    ...getColumnSliderProps(
      "prevalenceFloat2",
      sliderRanges.prevalenceFloatRange2,
      afterSliderChange
    ),
    render: (value: string | number) => <span>{value || "N/A"}</span>,
  };

  if (filters.dctSubCapability) {
    dctCols.forEach((col: any) => {
      tableCols.push(col);
    });
  }
  if (filters.prevalenceDisease.length === 1) {
    tableCols.push(diseaseCol);
  }
  if (filters.prevalenceDisease.length === 2) {
    tableCols.push(diseaseCol);
    tableCols.push(diseaseCol2);
  }
  return tableCols;
}
