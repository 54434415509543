import React, { useState, useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";

interface CustomErrorBarProps {
  open: boolean;
  content: string;
  duration?: number;
  isSuccess?: boolean;
  onClose?: () => void;
}
const CustomErrorBar: React.FC<CustomErrorBarProps> = ({
  open,
  onClose,
  content,
  duration,
  isSuccess,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setShow(false);
    if (onClose !== undefined) onClose();
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={show}
      autoHideDuration={duration || 6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={isSuccess ? "success" : "error"}>
        {content}
      </Alert>
    </Snackbar>
  );
};
export default CustomErrorBar;
