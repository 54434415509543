export const MAP_CENTER = {
  lat: 35,
  lng: -95,
};

export const GLOBAL_MAP_CENTER = {
  lat: 35,
  lng: 0,
};

export const MAP_ZOOM = 4;
export const STATE_ZOOM = 6;
export const GLOBAL_ZOOM = 2;
export const dctColor = ["#ffffff", "#07A8F3", "#8612B4"];

export const SHOW_TIP_NUMMBER = 200;

export const dctTypeQuantiles = {
  min: 1,
  lower: 1,
  median: 2,
  upper: 3,
  max: 4,
};

export const pastColor = "#d52b1e";
export const upcomingColor = "#00af3f";

export const OUS_tips_text =
  "Currently, the top 10 sites for each country are displayed on the map for this study. If you wish to see more, please zoom into a specific region or select a country from the filters on the left.";
export const US_tips_text =
  "Currently, the top 200 sites are displayed on the map for this study. If you wish to see more, please zoom into a specific region or select a state from the filters on the left.";
