import * as _ from "lodash";
import { DISEASE_PREVALENCE_STATE_LEVEL } from "../../../../const/diseaseMapping";

export const maskDiseaseKey = (diseaseKey: string) =>
  Object.keys(DISEASE_PREVALENCE_STATE_LEVEL).includes(diseaseKey)
    ? "Percentage"
    : diseaseKey;

export const maskDemographicsKey = (demographicsKey: string) =>
  demographicsKey?.slice(-1) === "r"
    ? `${demographicsKey?.slice(0, -1)}n`
    : demographicsKey;

export const getRange = (data: any[], key: string) => {
  const arr = data.map((r: any) => r[key]);
  const min = _.min(arr) || 0;
  const max = _.max(arr) || 100;
  return [Math.floor(min), Math.ceil(max)];
};

export const getFilteredData = (props: {
  datasource: any[];
  types: string[];
  prevalenceValues: any;
  prevalenceDisease: any;
}) => {
  const { datasource, types, prevalenceValues, prevalenceDisease } = props;
  const { prevalenceFloat, prevalenceFloat2 } = prevalenceValues;
  const filteredData = datasource.filter((d: any) => {
    let isSelectedRange = false;
    if (!d.maturityCategory) d.maturityCategory = "N/A";
    const isSelectedType = types.includes(d.maturityCategory);

    if (prevalenceDisease.length === 0) return isSelectedType;
    if (prevalenceDisease.length === 1) {
      // 1 indication
      if (d.prevalenceFloat) {
        isSelectedRange =
          d.prevalenceFloat >= prevalenceFloat[0] &&
          d.prevalenceFloat <= prevalenceFloat[1];
      }
    }
    if (prevalenceDisease.length === 2) {
      // 2 indications
      if (d.prevalenceFloat && d.prevalenceFloat2) {
        isSelectedRange =
          d.prevalenceFloat >= prevalenceFloat[0] &&
          d.prevalenceFloat <= prevalenceFloat[1] &&
          d.prevalenceFloat2 >= prevalenceFloat2[0] &&
          d.prevalenceFloat2 <= prevalenceFloat2[1];
      }
      if (d.prevalenceFloat && !d.prevalenceFloat2) {
        isSelectedRange =
          d.prevalenceFloat >= prevalenceFloat[0] &&
          d.prevalenceFloat <= prevalenceFloat[1];
      }
      if (!d.prevalenceFloat && d.prevalenceFloat2) {
        isSelectedRange =
          d.prevalenceFloat2 >= prevalenceFloat2[0] &&
          d.prevalenceFloat2 <= prevalenceFloat2[1];
      }
    }
    return isSelectedType && isSelectedRange;
  });
  return filteredData;
};
