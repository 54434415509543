export const countryColumn: any = [
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    sorter: (a: any, b: any) => {
      const countryA = a.country?.toUpperCase();
      const countryB = b.country?.toUpperCase();
      if (countryA < countryB) return -1;
      if (countryA > countryB) return 1;
      return 0;
    },
  },
];

export const commomColumns: any = [
  {
    title: "State",
    dataIndex: "state",
    key: "state",
  },
  {
    title: "County",
    dataIndex: "county",
    key: "county",
  },
];
export const trialColumns: any = [
  {
    title: "Site Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Facility Golden ID",
    dataIndex: "facilityId",
    key: "facilityId",
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
  },
];
