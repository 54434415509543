import { MutationByString } from '../../api/apollo';

interface UpdateUserFiltersInput{
    input: {
      userName: string;
      nickName:string;
      filter:string;
    }
}
export const useUpdateUserFilters = () => {
    const UPDATE_USER_FILTERS = `
    mutation userFiltersMutations($input:[UserFilterInput]){
        upsertUserFilterOption(input : $input){
            guid,
            userName,
            nickName,
            filter
        }
    }
  `;
    const [updateUserFilters] = MutationByString(UPDATE_USER_FILTERS);
    const updateUserFilter = (inputs: UpdateUserFiltersInput) => updateUserFilters({ variables: inputs });
    return { updateUserFilter };
};
