import {
  ApolloClient,
  InMemoryCache,
  useMutation,
  DefaultOptions,
  gql,
  HttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Environment from "../config/environment";
import { ACCESS_TOKEN_KEY, MS_GRAPH_TOKEN_KEY } from "../auth/refreshToken";

const { apiUrl } = new Environment();

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const link = new HttpLink({
  uri: `${apiUrl}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const authToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const MSGraphToken = localStorage.getItem(MS_GRAPH_TOKEN_KEY);
  if (!authToken || !MSGraphToken) throw new Error("Missing auth token(s)");

  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : null,
      "x-ms-graph-token": MSGraphToken ? `Bearer ${MSGraphToken}` : null, // need to enable CORS policy for this header on API Gateway
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions,
});

export const MutationByString: any = (mutationString: string) => {
  const query = gql`
    ${mutationString}
  `;
  return useMutation(query);
};

export function QueryByStringWithClient<T = any>(
  queryString: string,
  variables?: any
) {
  const query = gql`
    ${queryString}
  `;
  return client.query<T>({ query, variables });
}
