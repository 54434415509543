import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';

const DemographicLegend = (props: { theme: string }) => {
    const { theme } = props;
    return (
        <div className={`choropleth-legends demographics ${theme}`}>
            <div className={`y-axis low ${theme}`}>
                <i className="x low" />
                <i className="x medium" />
                <i className="x high" />
            </div>
            <div className="x axis-label">
                <div className="axis-line">
                    <div className="x-axis-arrow axis" />
                    <CaretRightOutlined />
                </div>
                <span className="text">Population Demographics</span>
            </div>
        </div>
    );
};

export default DemographicLegend;
