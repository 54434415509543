import { COUNTRY_VERIFICATION } from '../const/countryVerification';
import { standardQuestions } from '../const/standardQuestions';

interface state{
    category: string,
    activeQuestionId:string|number
}
const initialData:state = {
    category: 'All',
    activeQuestionId: standardQuestions[0].id
};

const countryReducer = (state = initialData, action: any) => {
    switch (action.type) {
    case COUNTRY_VERIFICATION:
        // eslint-disable-next-line no-param-reassign
        state = {
            ...state,
            ...action.data
        };
        break;
    default:
    }
    return state;
};
export default countryReducer;
