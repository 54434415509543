export const formatStudyPhase = (studyPhase: string) => {
  let phase = studyPhase;
  if (studyPhase === "I") {
    phase = "Phase 1";
  } else if (studyPhase === "II") {
    phase = "Phase 2";
  } else if (studyPhase === "III") {
    phase = "Phase 3";
  } else if (studyPhase === "IV") {
    phase = "Phase 4";
  } else if (studyPhase === "I/II" || studyPhase === "Phase 1/Phase 2") {
    phase = "Phase 1/2";
  } else if (studyPhase === "II/III" || studyPhase === "Phase 2/Phase 3") {
    phase = "Phase 2/3";
  } else if (studyPhase === "III/IV" || studyPhase === "Phase 3/Phase 4") {
    phase = "Phase 3/4";
  }
  return phase;
};

export const formatUserName = (userName: string) =>
  userName?.split("-")[0] || userName;

export function unique(arr: any[], key: string) {
  const map: any = new Map();
  arr.forEach((item) => {
    if (!map.has(item[key])) {
      map.set(item[key], item);
    }
  });
  return [...map.values()];
}

export const getStartedNum = (datasource: any[]) => {
  const startedArr = datasource.filter(
    (country: any) => country?.status.toUpperCase() !== "NOT STARTED"
  );
  return startedArr.length;
};

export const getCompletedNum = (datasource: any[]) => {
  const completedArr = datasource.filter(
    (country: any) => country?.status.toUpperCase() === "FINALIZED"
  );
  return completedArr.length;
};

/**
 * transfer base64 data to blob and download the csv file.
 * @param base64Data response base64data
 * @param contentType data type
 * @param filename download file name. default demo.csv
 */
export const getDownloadFileFromBase64Data = async (
  base64Data: string,
  contentType = "text/plain",
  filename = "demo.csv"
) => {
  const base64Response = await fetch(
    `data:${contentType};base64,${base64Data}`
  );
  const blob = await base64Response.blob();
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename); // or any other extension
  link.click();
};
