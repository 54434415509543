import { combineReducers } from 'redux';
import countryReducer from './countryReducer';
import siteReducer from './siteReducer';
import mapReducer from './mapReducer';

export default combineReducers({
    countryReducer,
    siteReducer,
    mapReducer
});
