import { QueryByStringWithClient } from "./apollo";

export const graphqlStringMap = {
  fetchCurrentUserInfo: `
    query whoami {
      whoami {
        guid,
        givenName,
        surname,
        systemId,
        displayName,
        email,
        isInformaticist
      }
    }
  `,
  fetchTherapeuticAreaList: `
    query studiesSearch {
      therapeuticAreaList
    }
  `,
  fetchTrials: `
    query studies($searchTerm:String, $pageSize:Int, $pageNum:Int,$filter:String){
      studiesSearch (searchTerm:$searchTerm,pageSize:$pageSize, pageNum: $pageNum,filter:$filter){
        count,
        studies{
          id,
          studyAlias,
          therapeuticArea,
          primaryIndication,
          studyPhase,
          dueDate,
          lastUpdatedBy,
          createdBy,
          createdAt,
          countryVerificationStatus,
          preCountryValidationStatus
          siteSelectionStatus
        }
      }
    }
  `,
  fetchCountryVerificationInfos: `
    query countryVerifications ($studyAlias:String!){
      countryVerificationsSearch (studyAlias:$studyAlias){
          id,
          studyId,
          studyAlias,
          status,
          opt,
          totalPatients,
          totalSites,
          approvalDate,
          enrollmentDuration,
          firstSiteEnrollmentReady,
          trialLastPatient,
          siteStartupTime,
          lastUpdatedBy,
          createdAt,
          updatedAt,
          deletedAt,
          totalCountries,
          completedCountries,
          locked
      }
    }
  `,
  fetchCountryAndSiteInfo: `
    query combineQuery ($studyAlias: String!){
      countryVerificationTotals (studyAlias: $studyAlias){
        id,
        opt,
        status,
        studyAlias,
        totalPatients,
        totalSites,
        updatedAt,
        lastUpdatedBy,
        totalCountries,
        completedCountries,
        locked,
        createdAt
      },
      preCountryValidation(studyAlias: $studyAlias) {
        id,
        studyId,
        studyAlias,
        status,
        locked,
        lockedBy,
        lastUpdatedBy,
        question,
        createdAt,
        updatedAt,
      },
      siteSelectionsSearch(studyAlias:$studyAlias){
        status,
        siteSelectionId,
        sitePursued,
        siteContacted,
        sipSelected,
        selectedIndication,
        sitesNotStarted,
        siteIncluded,
        therapeuticArea,
        createdAt,
        lastUpdatedBy,
        updatedAt,
      }
    }
  `,
  fethPreCountryData: `
    query Query($studyAlias: String!){
      preCountryDatas(studyAlias: $studyAlias) {
          id
          preCountryValidationId
          studyAlias
          countryCode
          countryName
          status
          rationale
          feedback
          comments
          createdAt
          updatedAt
          deletedAt
      }
  }
    `,
  fetchPreCountryInfo: `
      query Query($studyAlias: String!){
        preCountryValidation(studyAlias: $studyAlias) {
            id
            studyId
            studyAlias
            status
            locked
            lockedBy
            lastUpdatedBy
            question
            createdAt
            updatedAt
        }
      }
    `,
  fetchCountryData: `
    query countryDatas($searchTerm:String!){
      countryDatasSearch(searchTerm : $searchTerm){
        id,
        rationale,
        countryVerificationId,
        studyAlias,
        countryCode,
        siteStartupTimeEst,
        enrollmentDurationEst,
        numSitesEst,
        numPatientsEst,
        status,
        lastUpdatedBy,
        siteStartupTime,
        enrollmentDuration,
        numSites,
        numPatients,
        enrollmentReady,
        maxFeasiblePatients,
        maxSites,
        minPatients,
        maxPatients,
        absMaxPatients,
        createdAt,
        updatedAt,
        deletedAt,
        numQuestionCompleted,
        numQuestion,
        psmRate,
        response,
        addtlResponse,
        locked,
        lockedBy
      }
    }
  `,
  fetchCountrySummaryData: `
     query countryData ($id:String!){
      countryDatasSearchById (id:$id){
        id,
        status,
        countryCode,
        siteStartupTimeEst,
        numSitesEst,
        numPatientsEst,
        enrollmentDurationEst,
        response,
        locked
      }
    }
  `,
  fetchAdditonalQuestion: `
    query studyAddtlQuestions($searchTerm:String!){
      studyAddtlQuestionsSearch(searchTerm : $searchTerm){
        id,
        countryVerificationId,
        studyAlias,
        order,
        question,
        createdAt,
        updatedAt,
        deletedAt
      }
    }
  `,
  fetchQuestionAnswers: `
    query studyAnswerQueries($countryDataId:String!){
      studyAnswersSearch(countryDataId : $countryDataId){
        id,
        countryDataId,
        studyAlias,
        questionId,
        response,
        addtlResponse
      }
    }
  `,
  fetchAllQuestionAnswers: `
    query combineQuery($countryDataId:String!){
      studyAddtlAnswersSearch(countryDataId : $countryDataId){
          id,
          countryDataId,
          studyAlias,
          studyAddtlQuestionId,
          response
      },
      studyAnswersSearch(countryDataId : $countryDataId){
          id,
          countryDataId,
          studyAlias,
          questionId,
          response
      }
    }
  `,
  fetchReport: `
  query countrySummary($countryDataId:String!){
    downloadCountrySummary(countryDataId : $countryDataId){
        study,
        country,
        response
    }
  }
  `,
  fetchSiteSelectionInfos: `
  query siteSelectionsSearch($studyAlias:String!){
    siteSelectionsSearch(studyAlias:$studyAlias){
      status,
      studyAlias,
      studyId,
      totalSites,
      sitesAvailable,
      siteIncluded,
      feedbackProvided,
      siteContacted,
      sitePursued,
      sipSelected,
      createdAt,
      lastUpdatedBy,
      updatedAt,
    }
  }
  `,
  fetchSiteSelectionData: `
    query siteData(
      $study:String!,
      $pageSize:Int,
      $pageNum:Int,
      $searchTerm:String,
      $countries:[String],
      $lillyStudyExperience:Boolean,
      $state:[String],
      $sipStatus:String,
      $contacted:String,
      $sipSelected: String,
      $pursued:String,
      $enrollmentFeasibility:[Int],
      $siteHealth:[Int],
      $previousTrials:[String],
      $currentTrials:[String],
      $order:[String],
      $geoMappingDatas:Boolean,
      $pediatricStudy:[String],
      $siteType:[String],
      $isStarred:String,
      $starredIds:[String],
      $region:[Float],
      $rationaleRating:[String],
      $sipFilter: [String],
      $indications: [String],
      $showMasterListSites: Boolean,
      $diversityFilters: [[String]],
      $diversityGroupOperators: [[String]],
      $targetGroupOperators: [String]
      ){
      siteDatasSearch(
        study:$study,
        pageSize:$pageSize,
        pageNum: $pageNum,
        searchTerm:$searchTerm,
        lillyStudyExperience:$lillyStudyExperience,
        countries:$countries,
        state:$state,
        sipStatus:$sipStatus,
        contacted:$contacted,
        pursued:$pursued,
        sipSelected:$sipSelected,
        enrollmentFeasibility:$enrollmentFeasibility,
        siteHealth:$siteHealth,
        previousTrials:$previousTrials,
        currentTrials:$currentTrials,
        order:$order,
        geoMappingDatas:$geoMappingDatas,
        pediatricStudy:$pediatricStudy,
        siteType:$siteType,
        isStarred:$isStarred,
        starredIds:$starredIds,
        region:$region,
        rationaleRating: $rationaleRating,
        sipFilter: $sipFilter,
        indications: $indications,
        showMasterListSites: $showMasterListSites,
        diversityFilters: $diversityFilters,
        diversityGroupOperators: $diversityGroupOperators,
        targetGroupOperators: $targetGroupOperators
        ){
          countInCountry,
          countInPsl,
          pursuedCountInFilter,
          contactedCountInFilter,
          hispanicDiversityScore,
          aaDiversityScore,
          count,
          siteDatas{
            id,
            studyAlias,
            therapeuticArea,
            siteSelectionId,
            facilityId,
            completedTrialList,
            siteType,
            numCompletetdDQSTrials,
            medHistoricPSM,
            mostCommonRationale,
            rationaleRating,
            psmRank,
            decile,
            color,
            siteHealth,
            classification,
            recommendation,
            insight,
            africanAmericanDiversity,
            africanAmericanDiversityScore,
            hispanicDiversity,
            hispanicDiversityScore,
            nativeDiversity,
            nativeDiversityScore,
            asianDiversity,
            asianDiversityScore,
            sipStatus,
            siteContacted,
            sitePursued,
            sipSelected,
            name,
            address,
            country,
            city,
            stateProvince,
            recentStudyStart,
            lillyStudyExperience,
            phase1Experience,
            pediatricStudy,
            indications,
            description,
            siteFlag,
            memberId,
            additionalComments,
            contactedComment,
            lastUpdatedBy,
            createdAt,
            updatedAt,
            deletedAt,
            started,
            fromMasterList,
            investigators,
            selectedIndication,
            selectedIndicationAbbreviation,
          },
          geoMappingDatas{
            country,
            county,
            address,
            city,
            state,
            lat,
            lng,
            name,
            facilityId,
            zip,
            siteContacted,
            sipSelected,
            sitePursued
        }
      }
    }
  `,
  fetchSiteRationales: `
    query rationales($siteDataId:String!){
      rationalesSearch(siteDataId:$siteDataId)
    }
  `,
  fetchInvestigatorsAndDiversities: `
   query combineQuery ($siteDataId:String!){
      siteInvestigatorsSearch(siteDataId:$siteDataId){
        id,
        studyAlias,
        siteDataId,
        goldenId,
        memberId,
        firstName,
        lastName,
        sipSiteInv,
        email,
        phone,
        dnu,
        specialty,
        fdaDebarment,
      },
      patientDiversitiesSearch(siteDataId:$siteDataId){
        id,
        studyAlias,
        siteDataId,
        ethnicity,
        country,
        numStudies,
        patCount,
        none,
        americanIndianAlaskaNative,
        asian,
        blackAfricanAmerican,
        multiple,
        nativeHawaiianPacIslander,
        white,
        relatedGoldenIds
        createdAt,
        updatedAt,
        deletedAt
        },
        getDiversityInfo(siteDataId: $siteDataId){
          goal,
          hispanicGoal,
          countyInfo{
            name,
            population,
            africanAmericanNumber,
            africanAmericanPercentage,
            africanAmericanRatio,
            hispanicPercentage,
            hispanicNumber,
            hispanicRatio,
            asianNumber,
            asianPercentage,
            asianRatio,
            nativeNumber,
            nativePercentage,
            nativeRatio
          },
          facilityInfo{
            africanAmericanPercentage,
            africanAmericanRatio,
            hispanicPercentage,
            hispanicRatio,
            asianPercentage,
            asianRatio,
            nativePercentage,
            nativeRatio
          },
          historicalInfo{
            totalHistoricalParticipantCount,
            africanAmericanNumber,
            africanAmericanPercentage,
            africanAmericanRatio,
            hispanicPercentage,
            hispanicNumber,
            hispanicRatio,
            asianNumber,
            asianPercentage,
            asianRatio,
            nativeNumber,
            nativePercentage,
            nativeRatio
          },
          africanAmericanDiversityIndex,
          hispanicDiversityIndex,
          nativeDiversityIndex,
          asianDiversityIndex
      }
      }`,
  fetchCountryList: `
    query countryList($study:String!){
      countryList(study: $study)
    }
  `,
  fetchSiteTypeList: `
      query siteTypeList{
        siteTypeList
      }
  `,

  fetchSiteDataByCountry: `
    query CountrySiteData($study:String!,$pageSize:Int!, $pageNum:Int!){
      pslCountryDatasSearch(study: $study,pageSize:$pageSize, pageNum: $pageNum){
        count,
        pslCountryDatas{
        id,
        siteSelectionId,
        status,
        complete,
        studyAlias,
        sitesAvailable,
        sitesContacted,
        sitesPursued,
        country,
        updatedAt
        }
      }
    }
  `,
  fetchRationaleList: `
    query rationales($rationale:Boolean!){
      rationaleList(rationale:$rationale)
    }
  `,
  fetchStateList: `
    query stateList($study:String!, $countries:[String!]!){
      stateListByCountry(study: $study, countries: $countries) {
        country
        states
      }
    }
  `,
  fetchPreviousTrials: `
    query previousTrials($study:String!,$searchTerm:String, $countries:[String!]){
      previousTrialsSearch(study: $study, searchTerm:$searchTerm, countries:$countries)
    }
  `,
  fetchCurrentTrials: `
    query currentTrials($study:String!,$searchTerm:String, $countries:[String!]){
      currentTrialsSearch(study: $study, searchTerm:$searchTerm,countries:$countries)
    }
  `,
  fetchDiversity: `
    query diversityInfo($siteDataId:String!){
      getDiversityInfo(siteDataId: $siteDataId){
        goal,
        countyInfo{
          name,
          population,
          africanAmericanNumber,
          africanAmericanPercentage,
          ratio
        },
        facilityInfo{
          africanAmericanNumber,
          africanAmericanPercentage,
          ratio
        },
        historicalInfo{
          historicalNumber,
          historicalPercentage,
          africanAmericanNumber,
          africanAmericanPercentage,
          ratio
        },
        di
      }
    }
  `,
  fetchMappingData: `
    query geoMappingSearch($study:String!, $state:String, $region:[Float], $sipFilter: [String], $country: String){
      geoMappingSearch(study:$study, state:$state, region:$region, sipFilter: $sipFilter, country: $country){
        name,
        facilityId,
        zip,
        city,
        state,
        county,
        country,
        address,
        lng,
        lat,
        siteContacted,
        sipSelected,
        sitePursued
      }
    }
  `,
  fetchFilterNicknames: `
    query nickNameSearch($userName:String!){
      nickNameSearch(userName:$userName)
    }
  `,
  fetchUserFilter: `
    query($userName:String!){
      userFilterSearch(userName:$userName){
          guid,
          nickName,
          userName,
          filter
      }
    }
  `,
  fetchDCTData: `
    query ($dctType:[String]!, $state:String, $region:[Float] ){
      dctDataSearch(dctType: $dctType, state:$state, region:$region){
        site_name,
        state,
        city,
        zip,
        address,
        lat,
        lng
      }
    }
  `,
  fetchGeoFilterOptions: `
    query {
      diversitySearch,
      dctDropdownList,
      taList,
      dctCapabilities {
        dctCapability,
        dctSubCapability,
        dctSubCapabilityDescription
      },
      prevalenceTaAndIndications{
        therapeuticArea
        indicationName
        indicationValue
        prevalenceSources
      },
      pastTrialSiteLocationCountries
    }
  `,
  fetcTrialsList: `
    query ($ta:String!) {
      upcomingDropdownSearch(ta:$ta),
      pastDropdownSearch(ta:$ta)
    }
  `,
  fetchDiseaseList: `
    query diseaseList($ta:String!) {
      diseaseListFromTa(ta:$ta)
    }
  `,
  fetchCVResponse: `
    query exportCVResponses ( $id: String!) {
      exportCVResponses (id: $id) {
      filename
      contentType
      base64data
      }
    }
  `,
  fetchRationaleCommonData: `
      query rationaleCommon ($siteDataId: String!) {
        rationaleCommon (siteDataId: $siteDataId) {
          studyAlias
          therapeuticArea
          primaryIndication
          updatedAt
          rationale
        }
      }
  `,
  fetchCountries: `
      query Query { countries }
  `,
  fetchMasterSiteData: `
      query MasterSiteDatasSearch(
        $pageSize: Int!,
        $pageNumber: Int!,
        $country: String,
        $lillyStudyExperience: Int,
        $pediatricStudy: Int,
        $searchTerm: String,
        $sipStatus: Boolean,
        $siteHealth: [Int],
        $studyAlias: String!,
        $order:[String],
        $diversityFilters: [[String]],
        $diversityGroupOperators: [[String]],
        $targetGroupOperators: [String]
      ) {
        masterSiteDatasSearch(
          pageSize: $pageSize,
          pageNum: $pageNumber,
          country: $country,
          lillyStudyExperience: $lillyStudyExperience,
          pediatricStudy: $pediatricStudy,
          searchTerm: $searchTerm,
          sipStatus: $sipStatus,
          siteHealth: $siteHealth,
          studyAlias: $studyAlias,
          order:$order,
          diversityFilters: $diversityFilters,
          diversityGroupOperators: $diversityGroupOperators,
          targetGroupOperators: $targetGroupOperators
          ) {
            count
            masterSiteDatas {
                id
                personName
                personGoldenID
                facilityGoldenID
                siteName
                siteState
                siteCity
                siteHealth
                therapeuticArea
                sipStatus
                color
                pediatricStudyExperience
                phase1Experience
                lillyStudyExperience
                addedToPISLList
                indication
                hispanicDiversityIndex
                blackDiversityIndex
                asianDiversityIndex
                nativeDiversityIndex
                hispanicCategory
                blackCategory
                asianCategory
                nativeCategory
            }
        }
      }
  `,
  fetchPsmDecilesByCountry: `
    query FetchPSMDecilesByCountry ($studyAlias: String!, $intersection: Boolean) {
      fetchPSMDecilesByCountry (studyAlias: $studyAlias, intersection: $intersection) {
        countryDeciles {
          country
          decile0
          decile10
          decile20
          decile30
          decile40
          decile50
          decile60
          decile70
          decile80
          decile90
          total
        }
        selected_indication
        selected_indication_abbreviation
        selected_indication_number
        selected_therapeutic_area
      }
    }`,
  fetchRiskIndicators: `query siteQualityRiskIndicators($facilityId: String!) {
    siteQualityRiskIndicators(
      facilityId: $facilityId
    ) {
      classifications {
        description
        riskScore
        color
        label
        timepoint
      }
      overallRiskScore {
        description
        riskScore
        color
        label
      }
    }
  }`,
};

export const fetchByGraphqlString = (graphqlString: string) =>
  QueryByStringWithClient(graphqlString);
