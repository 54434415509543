import React from 'react';
import { styled } from '@mui/material/styles';
import { Backdrop, CircularProgress } from '@mui/material';

interface CustomLoadingProps {
  open: boolean;
}

const CustomLoading: React.FC<CustomLoadingProps> = ({ open }) => (
    <StyledBackdrop open={open}>
        <CircularProgress style={{ color: '#d52b1e' }} />
    </StyledBackdrop>
);

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    background: 'transparent'
}));

export default CustomLoading;
