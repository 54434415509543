type EnvVars = Partial<{
  REACT_APP_URI: string;
  REACT_APP_GOOGLE_MAP_KEY: string;
  }>;

export default class Environment {
    constructor(public config = process.env as EnvVars) {
        this.config = config;
    }

    get apiUrl() {
        return this.config.REACT_APP_URI || '';
    }

    get googleMapKey() {
        return this.config.REACT_APP_GOOGLE_MAP_KEY || '';
    }
}
