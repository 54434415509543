import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import './index';
import { connect } from 'react-redux';
import { Quantile } from '../../../../@types/Quantile';
import * as mapActions from '../../../../actions/map';

const { Option } = Select;
export const defaultThemeName = 'BuPu';
export const schemes: Array<any> = [
    {
        name: 'BuPu',
        colors: [
            // row1
            '#C0C0C0', // (cell: x:1, y:1)
            '#ace4e4', // (cell: x:2, y:1)
            '#5ac8c8', // (cell: x:3, y:1)
            // row2
            '#dfb0d6', // (cell: x:1, y:2)
            '#a5add3', // (cell: x:2, y:2)
            '#5698b9', // (cell: x:3, y:2)
            // row3
            '#be64ac', // (cell: x:1, y:3)
            '#8c62aa', // (cell: x:2, y:3)
            '#3b4994' // (cell: x:3, y:3)
        ]
    },
    {
        name: 'RdBu',
        colors: [
            '#C0C0C0',
            '#e4acac',
            '#c85a5a',
            '#b0d5df',
            '#ad9ea5',
            '#985356',
            '#64acbe',
            '#627f8c',
            '#574249'
        ]
    },
    {
        name: 'GnBu',
        colors: [
            '#C0C0C0',
            '#b5c0da',
            '#6c83b5',

            '#b8d6be',
            '#90b2b3',
            '#567994',

            '#73ae80',
            '#5a9178',
            '#2a5a5b'
        ]
    },
    {
        name: 'PuOr',
        colors: [
            '#C0C0C0',
            '#e4d9ac',
            '#c8b35a',

            '#cbb8d7',
            '#c8ada0',
            '#af8e53',

            '#9972af',
            '#976b82',
            '#804d36'
        ]
    }
];

const quantitles4Colors = [
    // '#9C438A', '#81439C', '#623171', '#2E204B', // row4 index: 0-3
    // '#BE66AB', '#8C64AA', '#3A4894', '#303D75', // row3 index: 4-7
    // '#DFB2D6', '#A3AFD3', '#5698B9', '#356278', // row2 index: 8-11
    // '#C0C0C0', '#ADE3E4', '#5AC8C8', '#3B9494' // row1 index: 12-15

    // row1
    '#C0C0C0', // (cell: x:1, y:1)
    '#ADE3E4', // (cell: x:2, y:1)
    '#5AC8C8', // (cell: x:3, y:1)
    '#3B9494', // (cell: x:4, y:1)
    // row2
    '#DFB2D6', // (cell: x:1, y:2)
    '#A3AFD3', // (cell: x:2, y:2)
    '#5698B9', // (cell: x:3, y:2)
    '#356278', // (cell: x:4, y:2)
    // row3
    '#BE66AB', // (cell: x:1, y:3)
    '#8C64AA', // (cell: x:2, y:3)
    '#3A4894', // (cell: x:3, y:3)
    '#303D75', // (cell: x:4, y:3)
    // row4
    '#9C438A', // (cell: x:1, y:4)
    '#81439C', // (cell: x:2, y:4)
    '#623171', // (cell: x:3, y:4)
    '#2E204B' // (cell: x:4, y:4)

];


export function getColor(
    colors: string[],
    values: {
        x: number | undefined;
        y?: number | undefined;
    },
    quantitles: {
        xQuantile?: Quantile,
        yQuantile?: Quantile
    }
) {
    const { x, y } = values;
    const { xQuantile, yQuantile } = quantitles;

    let color = 'transparent';
    const xLower = xQuantile?.lower || 0;
    const xUpper = xQuantile?.upper || 0;
    const xMax = xQuantile?.max || 0;

    const yLower = yQuantile?.lower || 0;
    const yUpper = yQuantile?.upper || 0;
    const yMax = yQuantile?.max || 0;


    if (!y && !x) return;

    if (y && !x) {
        if (y >= 0 && y <= yLower) {
            color = colors[0];
        } else if (y > yLower && y <= yUpper) {
            color = colors[3];
        } else if (y > yUpper && y <= yMax) {
            color = colors[6];
        }
    }

    if (!y && x) {
        if (x >= 0 && x <= xLower) {
            color = colors[0];
        }
        if (x > xLower && x <= xUpper) {
            color = colors[1];
        }
        if (x > xUpper && x <= xMax) {
            color = colors[2];
        }
    }

    if (y && x) {
        if (y >= 0 && y <= yLower) {
            if (x >= 0 && x <= xLower) {
                color = colors[0];
            }
            if (x > xLower && x <= xUpper) {
                color = colors[1];
            }
            if (x > xUpper && x <= xMax) {
                color = colors[2];
            }
        } else if (y > yLower && y <= yUpper) {
            if (x >= 0 && x <= xLower) {
                color = colors[3];
            }
            if (x > xLower && x <= xUpper) {
                color = colors[4];
            }

            if (x > xUpper && x <= xMax) {
                color = colors[5];
            }
        } else if (y > yUpper && y <= yMax) {
            if (x >= 0 && x <= xLower) {
                color = colors[6];
            }
            if (x > xLower && x <= xUpper) {
                color = colors[7];
            }
            if (x > xUpper && x <= xMax) {
                color = colors[8];
            }
        }
    }
    // eslint-disable-next-line consistent-return
    return color;
}

export function getColorQuantiles4(
    // colors: string[],
    values: {
        x?: number | undefined;
        y?: number | undefined;
  },
    quantitles:{
        xQuantile?: Quantile,
        yQuantile?: Quantile
   }
) {
    const { x, y } = values;
    const { xQuantile, yQuantile } = quantitles;

    let color = 'transparent';
    const xLower = xQuantile?.lower || 0;
    const xMedian = xQuantile?.median || 0;
    const xUpper = xQuantile?.upper || 0;
    const xMax = xQuantile?.max || 0;

    const yLower = yQuantile?.lower || 0;
    const yMedian = yQuantile?.median || 0;
    const yUpper = yQuantile?.upper || 0;
    const yMax = yQuantile?.max || 0;


    if (!y && !x) return;

    if (!y && x) { // only x axis
        if (x >= 0 && x <= xLower) {
            color = quantitles4Colors[0]; // cell1
        }
        if (x > xLower && x <= xMedian) {
            color = quantitles4Colors[1]; // cell2
        }
        if (x > xMedian && x <= xUpper) {
            color = quantitles4Colors[2]; // cell3
        }
        if (x > xUpper && x <= xMax) {
            color = quantitles4Colors[3]; // cell4
        }
    }

    if (y && !x) { // only y value
        if (y >= 0 && y <= yLower) {
            color = quantitles4Colors[0]; // cell1
        }
        if (y > yLower && y <= yMedian) {
            color = quantitles4Colors[4]; // cell2
        }
        if (y > yMedian && y <= yUpper) {
            color = quantitles4Colors[8]; // cell3
        }
        if (y > yUpper && y <= yMax) {
            color = quantitles4Colors[12]; // cell4
        }
    }

    if (y && x) { // x value + y value
        if (y >= 0 && y <= yLower) { // row1
            if (x >= 0 && x <= xLower) {
                color = quantitles4Colors[0]; // cell1
            }
            if (x > xLower && x <= xMedian) {
                color = quantitles4Colors[1]; // cell2
            }
            if (x > xMedian && x <= xUpper) {
                color = quantitles4Colors[2]; // cell3
            }
            if (x > xUpper && x <= xMax) {
                color = quantitles4Colors[3]; // cell4
            }
        } else if (y > yLower && y <= yMedian) { // row2
            if (x >= 0 && x <= xLower) {
                color = quantitles4Colors[4]; // cell1
            }
            if (x > xLower && x <= xMedian) {
                color = quantitles4Colors[5]; // cell2
            }
            if (x > xMedian && x <= xUpper) {
                color = quantitles4Colors[6]; // cell3
            }
            if (x > xUpper && x <= xMax) {
                color = quantitles4Colors[7]; // cell4
            }
        } else if (y > yMedian && y <= yUpper) { // row3
            if (x >= 0 && x <= xLower) {
                color = quantitles4Colors[8]; // cell1
            }
            if (x > xLower && x <= xMedian) {
                color = quantitles4Colors[9]; // cell2
            }
            if (x > xMedian && x <= xUpper) {
                color = quantitles4Colors[10]; // cell3
            }
            if (x > xUpper && x <= xMax) {
                color = quantitles4Colors[11]; // cell4
            }
        } else if (y > yUpper && y <= yMax) { // row4
            if (x >= 0 && x <= xLower) {
                color = quantitles4Colors[12]; // cell1
            }
            if (x > xLower && x <= xMedian) {
                color = quantitles4Colors[13]; // cell2
            }
            if (x > xMedian && x <= xUpper) {
                color = quantitles4Colors[14]; // cell3
            }
            if (x > xUpper && x <= xMax) {
                color = quantitles4Colors[16]; // cell4
            }
        }
    }
    // eslint-disable-next-line consistent-return
    return color;
}

export const getSchemesNames = (schemes:any) => {
    const colorArr: string[] = [];
    schemes.forEach((s:any) => {
        colorArr.push(s.name);
    });
    return colorArr;
};

const ColorSchemes: React.FC<any> = (props) => {
    const { themeColor } = props;
    const [names, setNames] = useState<string[]>([]);
    const [theme, setTheme] = useState<string>(themeColor);

    const onChange = (value: string) => {
        const scheme = schemes.find(s => s.name === value);
        const cols: string[] = scheme.colors || [];
        setTheme(value);
        props.renderMap({
            themeColor: value,
            colors: cols
        });
    };


    useEffect(() => {
        setTheme(themeColor);
    }, [themeColor]);

    useEffect(() => {
        const colorArr = getSchemesNames(schemes);
        setNames(colorArr);
    }, []);

    return (
        <div className="color-schemes">
            <span className="label">Color scheme</span>
            <Select
                style={{ width: 120 }}
                value={theme}
                onChange={onChange}
            >
                {names.map((c: string) => (
                    <Option value={c} key={c}>
                        {c}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    renderMap: (val: any) => dispatch(mapActions.renderMap(val))
});

const mapStateToProps = (state: any) => ({
    themeColor: state.mapReducer.themeColor,
    colors: state.mapReducer.colors
});

export default connect(mapStateToProps, mapDispatchToProps)(ColorSchemes);
