import { useState, useEffect } from "react";
import { addSingleMarkers } from "./utils";
import {
  TrialLocationsList,
  LocationList,
  CustomMarker,
} from "../../../../@types/Map";

const checkLocationsLength = (
  list: LocationList[],
  markers: CustomMarker[]
): boolean => {
  return list.length === markers.length;
};

const determineMarkerStateChange = (
  locationList: LocationList[],
  markers: CustomMarker[],
  setMarkerState: (markers: CustomMarker[]) => void,
  map: google.maps.Map
): CustomMarker[] => {
  if (checkLocationsLength(locationList, markers)) return markers;
  if (locationList.length === 0 && markers.length > 0) {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    setMarkerState([]);
    return [];
  }
  if (
    locationList.length > markers.length ||
    locationList.length < markers.length
  ) {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    const newMarkers = addSingleMarkers({ locationList, map });
    setMarkerState(newMarkers);
    return newMarkers;
  }
  return markers;
};

export function useMarkers(
  locationList: TrialLocationsList = {
    upcomingTrialLocations: [],
    pastTrialLocations: [],
  },
  map?: google.maps.Map
) {
  const [allMarkers, setAllMarkers] = useState<CustomMarker[]>([]);
  const [upcomingTrialMarkers, setUpcomingTrialMarkers] = useState<
    CustomMarker[]
  >([]);
  const [pastTrialMarkers, setPastTrialMarkers] = useState<CustomMarker[]>([]);

  useEffect(() => {
    if (!map) return;
    const { upcomingTrialLocations, pastTrialLocations } = locationList;
    let markers: CustomMarker[] = [];
    if (
      checkLocationsLength(upcomingTrialLocations, upcomingTrialMarkers) &&
      checkLocationsLength(pastTrialLocations, pastTrialMarkers)
    )
      return;
    const upcomingMarkers = determineMarkerStateChange(
      upcomingTrialLocations,
      upcomingTrialMarkers,
      setUpcomingTrialMarkers,
      map
    );
    const pastMarkers = determineMarkerStateChange(
      pastTrialLocations,
      pastTrialMarkers,
      setPastTrialMarkers,
      map
    );

    markers = [...upcomingMarkers, ...pastMarkers];
    setAllMarkers(markers);
  }, [locationList]);

  return {
    allMarkers,
    setAllMarkers,
  };
}
