import React, { useEffect, useState } from "react";
import AppBody from "../AppBody";
import Header from "../Header";
import { useQueryUser } from "../../hooks/useQueryUser";
import UserContext from "../../provider/UserContextProvider";
import { WhoAmI } from "../../@types/UserInfo";
const AppRoot: React.FC<Record<string, never>> = () => {
  const [whoami, setWhoami] = useState<WhoAmI | undefined>();

  useEffect(() => {
    const getUser = async () => {
      const { data: whoami } = await useQueryUser();
      setWhoami(whoami);
    };
    getUser();
  }, []);

  return (
    <UserContext.Provider value={whoami}>
      <Header />
      <AppBody />
    </UserContext.Provider>
  );
};

export default AppRoot;
