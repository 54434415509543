import { QueryByStringWithClient } from "../../api/apollo";

export const useQueryUser = async () => {
  const QUERY_USER = `
    query whoami {
      whoami {     
        guid,
        givenName,
        surname,
        systemId,
        displayName,
        email,
        isInformaticist
      }
    }
  `;
  return await QueryByStringWithClient(QUERY_USER);
};
