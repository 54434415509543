/* eslint no-param-reassign: ["error", { "props": false }] */
import {
    SITE_SELECTION, SEARCH_INVESTIGATOR, REFRESH_SITE_LIST, SET_MASTER_FILTERS, INIT_MASTER_PAGINATION
} from '../const/siteSelection';

export enum DIVERSITY_KEYS {
    HISPANIC = 'hispanicDiversity',
    AFRICAN_AMERICAN = 'africanAmericanDiversity',
    ASIAN = 'asianDiversity',
    NATIVE = 'nativeDiversity'
}

interface state{
    country:string
    count: number | null
    countInCountry: number | null
    countInPsl: number | null
    contactedCountInFilter: number | null
    pursuedCountInFilter: number | null
    africanAmericanDiversityScore: number | null
    hispanicDiversityScore: number | null
    nativeDiversityScore: number | null
    asianDiversityScore: number | null
    starredIds:string[]
    siteFilters:any
    appliedDiversity: string[]
    searchTerm: string | null,
    refreshSiteList: boolean,
    masterFilters: any;
    initMasterPagination: boolean;
}
const initialData: state = {
    country: '',
    searchTerm: '',
    count: 0,
    countInCountry: 0,
    countInPsl: 0,
    contactedCountInFilter: 0,
    pursuedCountInFilter: 0,
    africanAmericanDiversityScore: 0,
    hispanicDiversityScore: 0,
    nativeDiversityScore: 0,
    asianDiversityScore: 0,
    starredIds: [],
    siteFilters: {},
    masterFilters: {},
    appliedDiversity: [DIVERSITY_KEYS.HISPANIC, DIVERSITY_KEYS.AFRICAN_AMERICAN, DIVERSITY_KEYS.ASIAN, DIVERSITY_KEYS.NATIVE],
    refreshSiteList: false,
    initMasterPagination: false
};

const siteReducer = (state = initialData, action: any) => {
    switch (action.type) {
    case SITE_SELECTION:
        // eslint-disable-next-line no-param-reassign
        state = {
            ...state,
            ...action.data
        };
        break;
    case SEARCH_INVESTIGATOR:
        // eslint-disable-next-line no-param-reassign
        state = {
            ...state,
            searchTerm: action.data
        };
        break;
    case REFRESH_SITE_LIST:
        // eslint-disable-next-line no-param-reassign
        state = {
            ...state,
            refreshSiteList: action.data
        };
        break;
    case SET_MASTER_FILTERS:
        // eslint-disable-next-line no-param-reassign
        state = {
            ...state,
            masterFilters: action.data
        };
        break;
    case INIT_MASTER_PAGINATION:
        // eslint-disable-next-line no-param-reassign
        state = {
            ...state,
            initMasterPagination: action.data
        };
        break;
    default:
    }
    return state;
};
export default siteReducer;
