import * as React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import CustomLoading from "../../../../components/CustomLoading/CustomLoading";
import "./index.scss";
import Environment from "../../../../config/environment";
import { MapContainerProps, TrialLocationsList } from "../../../../@types/Map";

import Map from "./Map";
import {
  GLOBAL_MAP_CENTER,
  GLOBAL_ZOOM,
  MAP_CENTER,
  MAP_ZOOM,
  OUS_tips_text,
  SHOW_TIP_NUMMBER,
  US_tips_text,
  pastColor,
  upcomingColor,
} from "./mapConstants";

const { googleMapKey } = new Environment();

const render = (status: Status) => (
  <CustomLoading open={status === "LOADING"} />
);

export const MapContainer: React.VFC<MapContainerProps> = ({
  getCurrentBounds,
  loading,
  filters,
  diseaseQuantiles,
  diseaseQuantiles2,
  demoQuantiles,
  pastTrialData,
  upcomingTrialData,
  dctData,
  choroplethData,
  originGlobalData,
  demographicsKey,
  demographicsText,
  diseaseKey,
  toggleTable,
  showTable,
  mapColor,
  mapColorArr,
  showSwitch,
}) => {
  const [zoom, setZoom] = React.useState(MAP_ZOOM);
  const [upcomingTrialMarkers, setUpcomingTrialMarkers] =
    React.useState<any[]>();
  const [center, setCenter] =
    React.useState<google.maps.LatLngLiteral>(MAP_CENTER);
  const [showModal, setShowModal] = React.useState(false);
  const [locationList, setLocationList] = React.useState<TrialLocationsList>();

  React.useEffect(() => {
    let markers = upcomingTrialData.slice(0);
    if (filters.mapScope === "US") {
      if (upcomingTrialData?.length > SHOW_TIP_NUMMBER && zoom <= MAP_ZOOM) {
        markers = markers.slice(0, SHOW_TIP_NUMMBER);
        setShowModal(true);
      }
    } else if (filters.mapScope === "OUS") {
      if (upcomingTrialData?.length > SHOW_TIP_NUMMBER && zoom <= GLOBAL_ZOOM) {
        // MDITEVER-12249: display the top 10 sites from each country when map is not zoomed in.
        const countryObj: any = {};
        markers.forEach((obj: any) => {
          const country = obj.country;
          if (countryObj[country]) {
            const exsitingTrial = countryObj[country];
            if (exsitingTrial.length < 10) exsitingTrial.push(obj);
          } else {
            const trials = [];
            trials.push(obj);
            countryObj[country] = trials;
          }
        });
        markers = Object.values(countryObj).flat();
        setShowModal(true);
      }
    }
    setUpcomingTrialMarkers(markers);
    return () => {
      Modal.destroyAll();
    };
  }, [upcomingTrialData, zoom]);

  React.useEffect(() => {
    setCenter(filters.mapScope === "OUS" ? GLOBAL_MAP_CENTER : MAP_CENTER);
    setZoom(filters.mapScope === "OUS" ? GLOBAL_ZOOM : MAP_ZOOM);
  }, [filters.mapScope]);

  /**
   * we are getting the location list for past trail when we select filter from the map
   * we received data in form of array of object with lat and lng values
   */
  React.useEffect(() => {
    const getPastTrailLocation =
      (pastTrialData?.length &&
        pastTrialData?.map(({ lat, lng, facilityId }) => ({
          lat: Number(lat),
          lng: Number(lng),
          color: pastColor,
          type: "past",
          facilityId,
        }))) ||
      [];

    /**
     * we are getting the location list for upcoming trail when we select filter from the map
     * we received data in form of array of object with lat and lng values
     */

    const getUpcomingTrialLocation =
      (upcomingTrialData?.length &&
        upcomingTrialData?.map(({ lat, lng, facilityId }) => ({
          lat: Number(lat),
          lng: Number(lng),
          color: upcomingColor,
          type: "upcoming",
          facilityId,
        }))) ||
      [];

    const trialLocationsList =
      {
        pastTrialLocations: getPastTrailLocation,
        upcomingTrialLocations: getUpcomingTrialLocation,
      } || {};
    setLocationList(trialLocationsList);
  }, [pastTrialData, upcomingTrialData]);

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <CustomLoading open={loading} />
      <Wrapper apiKey={googleMapKey} render={render} data-testid="map-wrapper">
        <Map
          center={center}
          zoom={zoom}
          style={{ flexGrow: "1", height: "100%" }}
          choroplethDataSource={choroplethData}
          originGlobalData={originGlobalData}
          demographicsKey={demographicsKey}
          demographicsText={demographicsText}
          diseaseText={filters.disease?.title}
          diseaseKey={diseaseKey}
          filters={filters}
          diseaseQuantiles={diseaseQuantiles}
          diseaseQuantiles2={diseaseQuantiles2}
          demoQuantiles={demoQuantiles}
          toggleTable={toggleTable}
          showTable={showTable}
          getCurrentBounds={getCurrentBounds}
          dctData={dctData}
          pastTrialData={pastTrialData}
          upcomingTrialData={upcomingTrialData}
          mapColor={mapColor}
          mapColorArr={mapColorArr}
          showSwitch={showSwitch}
          updateZoom={(zoom: number) => setZoom(zoom)}
          locationList={locationList}
        ></Map>
      </Wrapper>
      <Modal
        title=""
        wrapClassName="user-tips-modal"
        open={showModal}
        closable={false}
        footer={[
          <Button type="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>,
        ]}
      >
        <div className="user-tips">
          <ExclamationCircleOutlined />
          <div className="text">
            {filters.mapScope === "US" ? US_tips_text : OUS_tips_text}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MapContainer;
