export const DISEASE_PREVALENCE_MAP: any = {
    // --- Immunology
    AHRQ_atopic_dermatitis_without_eczema_treated_prevalence_percentage: 'Atopic Dermatitis without Eczema Treated Prevalence Percentage',
    AHRQ_Atopic_Dermatitis_with_Eczema_Treated_Prevalence_Percentage: 'Atopic Dermatitis with Eczema Treated Prevalence Percentage',
    AHRQ_Multiple_Sclerosis_Treated_Prevalence_Percentage: 'Multiple Sclerosis Treated Prevalence Percentage',
    AHRQ_Psoriasis_Treated_Prevalence_Percentage: 'Psoriasis Treated Prevalence Percentage',
    AHRQ_Rheumatoid_Arthritis_Treated_Prevalence_Percentage: 'Rheumatoid Arthritis Treated Prevalence Percentage',
    AHRQ_Systemic_Lupus_Erythematosus_SLE_Treated_Prevalence_Percentage: 'Systemic Lupus Erythematosus (SLE) Treated Prevalence Percentage',
    // --- Cardiovascular
    AHRQ_overweight_reated_revalence_ercentage: 'Overweight Treated Prevalence Percentage',
    // --- Endocrinology
    percent_pop_with_obesity: 'Total Obesity Percentage',
    AHRQ_nonalcoholic_steatohepatitis_nash_treated_prevalence_percentage: 'Non-alcoholic Steatohepatitis (NASH) Treated Prevalence Percentage',
    Percentage: 'Total Diagnosed Diabetes in Adults Aged 20+ Years',
    tot_prev_adrd_cnt_all:
    "Total Alzheimer's Disease and Related Dementias Count",
    // tot_prev_adrd_cnt_asian: "Alzheimer's Disease and Related Dementias Count: Asian",
    // tot_prev_adrd_cnt_black: "Alzheimer's Disease and Related Dementias Count: Black",
    // tot_prev_adrd_cnt_hispanic: "Alzheimer's Disease and Related Dementias Count: Hispanic",
    // tot_prev_adrd_cnt_native_american: "Alzheimer's Disease and Related Dementias Count: Native American",
    // tot_prev_adrd_cnt_other: "Alzheimer's Disease and Related Dementias Count: Other",
    // tot_prev_adrd_cnt_white: "Alzheimer's Disease and Related Dementias Count: White",
    // pct_adrd_by_race_asian: "Alzheimer's Disease and Related Dementias Percentage: Asian",
    // pct_adrd_by_race_black: "Alzheimer's Disease and Related Dementias Percentage: Black",
    // pct_adrd_by_race_hispanic: "Alzheimer's Disease and Related Dementias Percentage: Hispanic",
    // pct_adrd_by_race_native_american: "Alzheimer's Disease and Related Dementias Percentage: Native American",
    // pct_adrd_by_race_other: "Alzheimer's Disease and Related Dementias Percentage: Other",
    // pct_adrd_by_race_white: "Alzheimer's Disease and Related Dementias Percentage: White",
    prev_adrd_cases_per_100k_pop_all:
    "Total Alzheimer's Disease and Related Dementias Cases per 100k",
    prev_adrd_rate_pop_all:
    "Alzheimer's Disease and Related Dementias Prevalence Rate",
    // prev_adrd_cases_per_100k_pop_asian: " Alzheimer's Disease and Related Dementias Cases per 100k: Asian",
    // prev_adrd_rate_pop_asian: "Alzheimer's Disease and Related Dementias Prevalence Rate: Asian",
    // prev_adrd_cases_per_100k_pop_black: "Alzheimer's Disease and Related Dementias Cases per 100k: Black",
    // prev_adrd_rate_pop_black: "Alzheimer's Disease and Related Dementias Prevalence Rate: Black",
    // prev_adrd_cases_per_100k_pop_hispanic:
    //     "Alzheimer's Disease and Related Dementias Cases per 100k: Hispanic",
    // prev_adrd_rate_pop_hispanic: "Alzheimer's Disease and Related Dementias Prevalence Rate: Hispanic",
    // prev_adrd_cases_per_100k_pop_native_american:
    //     "Alzheimer's Disease and Related Dementias Cases per 100k: Native American",
    // prev_adrd_rate_pop_native_american: "Alzheimer's Disease and Related Dementias Prevalence Rate: Native American",
    // prev_adrd_cases_per_100k_pop_other: "Alzheimer's Disease and Related Dementias Cases per 100k: Other",
    // prev_adrd_rate_pop_other: "Alzheimer's Disease and Related Dementias Prevalence Rate: Other",
    // prev_adrd_cases_per_100k_pop_white: "Alzheimer's Disease and Related Dementias Cases per 100k: White",
    // prev_adrd_rate_pop_white: "Alzheimer's Disease and Related Dementias Prevalence Rate: White",
    all_all_cancer_sites_asr_100k: 'All Cancer Sites Rate per 100k',
    all_all_cancer_sites_average_annual_count:
    'All Cancer Sites Average Annual Count',
    all_bladder_asr_100k: 'Bladder Cancer Rate per 100K',
    all_bladder_average_annual_count: 'Bladder Cancer Average Annual Count',
    all_brain_and_ons_asr_100k: 'Brain Cancer Rate per 100K',
    all_brain_and_ons_average_annual_count: 'Brain Cancer Average Annual Count',
    all_breast_female_in_situ_asr_100k:
    'Female Breast Cancer in Situ Rate per 100K',
    all_breast_female_in_situ_average_annual_count:
    'Female Breast Cancer in Situ Average Annual Count',
    all_breast_female_asr_100k: 'Female Breast Cancer Rate per 100K ',
    all_breast_female_average_annual_count:
    'Female Breast Cancer Average Annual Count',
    all_cervix_asr_100k: 'Cervical Cancer Rate per 100K',
    all_cervix_average_annual_count: 'Cervical Cancer Average Annual Count',
    all_colon_and_rectum_asr_100k: 'Rectal Colon Cancer Rate per 100K',
    all_colon_and_rectum_average_annual_count:
    'Rectal Colon Cancer Average Annual Count',
    all_esophagus_asr_100k: 'Esophageal Cancer Rate per 100K',
    all_esophagus_average_annual_count: 'Esophageal Cancer Average Annual Count',
    all_kidney_and_renal_pelvis_asr_100k: 'Renal Pelvis Cancer Rate per 100k',
    all_kidney_and_renal_pelvis_average_annual_count:
    'Renal Pelvis Cancer Average Annual Count',
    all_leukemia_asr_100k: 'Leukemia Cancer Rate per 100k',
    all_leukemia_average_annual_count: 'Leukemia Cancer Annual Count',
    all_liver_and_bile_duct_asr_100k: 'Liver Bile Duct Cancer Rate Per 100k',
    all_liver_and_bile_duct_average_annual_count:
    'Liver Bile Duct Cancer Average Annual Count',
    all_lung_and_bronchus_asr_100k: 'Lung Bronchus Cancer Rate per 100k',
    all_lung_and_bronchus_average_annual_count:
    'Lung Bronchus Cancer Average Annual Count',
    all_melanoma_of_the_skin_asr_100k:
    'Melanoma of the Skin Cancer Rate per 100k',
    all_melanoma_of_the_skin_average_annual_count:
    'Melanoma of the Skin Cancer Average Annual Count',
    all_non_hodgkin_lymphoma_asr_100k:
    'Non Hodgkin Lymphoma Cancer Age Adjusted Rate per 100k',
    all_non_hodgkin_lymphoma_average_annual_count:
    'Non Hodgkin Lymphoma Cancer Average Annual Count',
    all_oral_cavity_and_pharynx_asr_100k:
    'Oral Cavity Pharynx Cancer Rate per 100k',
    all_oral_cavity_and_pharynx_average_annual_count:
    'Oral Cavity Pharynx Cancer Average Annual Count',
    all_ovary_asr_100k: 'Ovarian Cancer Age Adjusted Rate per 100K',
    all_ovary_average_annual_count: 'Ovarian Cancer Average Annual Count',
    all_pancreas_asr_100k: 'Pancreatic Cancer Rate per 100K',
    all_pancreas_average_annual_count: 'Pancreatic Cancer Average Annual Count',
    all_prostate_asr_100k: 'Prostate Cancer Rate per 100K',
    all_prostate_average_annual_count: 'Prostate Cancer Average Annual Counts',
    all_stomach_asr_100k: 'Stomach Cancer Rate per 100K',
    all_stomach_average_annual_count: 'Stomach Cancer Average Annual Counts',
    all_thyroid_asr_100k: 'Thyroid Cancer Rate per 100K',
    all_thyroid_average_annual_count: 'Thyroid Cancer Average Annual Count',
    all_uterus_corpus_and_uterus_nos_asr_100k: 'Uterine Cancer Rate per 100K',
    all_uterus_corpus_and_uterus_nos_average_annual_count:
    'Uterine Cancer Average Annual Count',
    //------------
    latest_bladder_asr_100k: 'Bladder Cancer Rate per 100K',
    latest_bladder_average_annual_count: 'Bladder Cancer Average Annual Count',
    latest_brain_and_ons_asr_100k: 'Brain Cancer Rate per 100K',
    latest_brain_and_ons_average_annual_count:
    'Brain Cancer Average Annual Count',
    latest_breast_female_in_situ_asr_100k:
    'Female Breast Cancer in Situ Rate per 100K',
    latest_breast_female_in_situ_average_annual_count:
    'Female Breast Cancer in Situ Average Annual Count',
    latest_breast_female_asr_100k: 'Female Breast Cancer Rate per 100K ',
    latest_breast_female_average_annual_count:
    'Female Breast Cancer Average Annual Count',
    latest_cervix_asr_100k: 'Cervical Cancer Rate per 100K',
    latest_cervix_average_annual_count: 'Cervical Cancer Average Annual Count',
    latest_colon_and_rectum_asr_100k: 'Rectal Colon Cancer Rate per 100K',
    latest_colon_and_rectum_average_annual_count:
    'Rectal Colon Cancer Average Annual Count',
    latest_esophagus_asr_100k: 'Esophageal Cancer Rate per 100K',
    latest_esophagus_average_annual_count:
    'Esophageal Cancer Average Annual Count',
    latest_kidney_and_renal_pelvis_asr_100k: 'Renal Pelvis Cancer Rate per 100k',
    latest_kidney_and_renal_pelvis_average_annual_count:
    'Renal Pelvis Cancer Average Annual Count',
    latest_leukemia_asr_100k: 'Leukemia Cancer Rate per 100k',
    latest_leukemia_average_annual_count: 'Leukemia Cancer Annual Count',
    latest_liver_and_bile_duct_asr_100k: 'Liver Bile Duct Cancer Rate Per 100k',
    latest_liver_and_bile_duct_average_annual_count:
    'Liver Bile Duct Cancer Average Annual Count',
    latest_lung_and_bronchus_asr_100k: 'Lung Bronchus Cancer Rate per 100k',
    latest_lung_and_bronchus_average_annual_count:
    'Lung Bronchus Cancer Average Annual Count',
    latest_melanoma_of_the_skin_asr_100k:
    'Melanoma of the Skin Cancer Rate per 100k',
    latest_melanoma_of_the_skin_average_annual_count:
    'Melanoma of the Skin Cancer Average Annual Count',
    latest_non_hodgkin_lymphoma_asr_100k:
    'Non Hodgkin Lymphoma Cancer Age Adjusted Rate per 100k',
    latest_non_hodgkin_lymphoma_average_annual_count:
    'Non Hodgkin Lymphoma Cancer Average Annual Count',
    latest_oral_cavity_and_pharynx_asr_100k:
    'Oral Cavity Pharynx Cancer Rate per 100k',
    latest_oral_cavity_and_pharynx_average_annual_count:
    'Oral Cavity Pharynx Cancer Average Annual Count',
    latest_ovary_asr_100k: 'Ovarian Cancer Age Adjusted Rate per 100K',
    latest_ovary_average_annual_count: 'Ovarian Cancer Average Annual Count',
    latest_pancreas_asr_100k: 'Pancreatic Cancer Rate per 100K',
    latest_pancreas_average_annual_count:
    'Pancreatic Cancer Average Annual Count',
    latest_prostate_asr_100k: 'Prostate Cancer Rate per 100K',
    latest_prostate_average_annual_count: 'Prostate Cancer Average Annual Counts',
    latest_stomach_asr_100k: 'Stomach Cancer Rate per 100K',
    latest_stomach_average_annual_count: 'Stomach Cancer Average Annual Counts',
    latest_thyroid_asr_100k: 'Thyroid Cancer Rate per 100K',
    latest_thyroid_average_annual_count: 'Thyroid Cancer Average Annual Count',
    latest_uterus_corpus_and_uterus_nos_asr_100k: 'Uterine Cancer Rate per 100K',
    latest_uterus_corpus_and_uterus_nos_average_annual_count:
    'Uterine Cancer Average Annual Count'
};
export const ONCOLOGY_STAGES_MAP: any = {
    'All Stages': {
        all_all_cancer_sites_asr_100k: 'All Cancer Sites Rate per 100k',
        all_all_cancer_sites_average_annual_count:
      'All Cancer Sites Average Annual Count',
        all_bladder_asr_100k: 'Bladder Cancer Rate per 100K',
        all_bladder_average_annual_count: 'Bladder Cancer Average Annual Count',
        all_brain_and_ons_asr_100k: 'Brain Cancer Rate per 100K',
        all_brain_and_ons_average_annual_count: 'Brain Cancer Average Annual Count',
        all_breast_female_in_situ_asr_100k:
      'Female Breast Cancer in Situ Rate per 100K',
        all_breast_female_in_situ_average_annual_count:
      'Female Breast Cancer in Situ Average Annual Count',
        all_breast_female_asr_100k: 'Female Breast Cancer Rate per 100K ',
        all_breast_female_average_annual_count:
      'Female Breast Cancer Average Annual Count',
        all_cervix_asr_100k: 'Cervical Cancer Rate per 100K',
        all_cervix_average_annual_count: 'Cervical Cancer Average Annual Count',
        all_colon_and_rectum_asr_100k: 'Rectal Colon Cancer Rate per 100K',
        all_colon_and_rectum_average_annual_count:
      'Rectal Colon Cancer Average Annual Count',
        all_esophagus_asr_100k: 'Esophageal Cancer Rate per 100K',
        all_esophagus_average_annual_count:
      'Esophageal Cancer Average Annual Count',
        all_kidney_and_renal_pelvis_asr_100k: 'Renal Pelvis Cancer Rate per 100k',
        all_kidney_and_renal_pelvis_average_annual_count:
      'Renal Pelvis Cancer Average Annual Count',
        all_leukemia_asr_100k: 'Leukemia Cancer Rate per 100k',
        all_leukemia_average_annual_count: 'Leukemia Cancer Annual Count',
        all_liver_and_bile_duct_asr_100k: 'Liver Bile Duct Cancer Rate Per 100k',
        all_liver_and_bile_duct_average_annual_count:
      'Liver Bile Duct Cancer Average Annual Count',
        all_lung_and_bronchus_asr_100k: 'Lung Bronchus Cancer Rate per 100k',
        all_lung_and_bronchus_average_annual_count:
      'Lung Bronchus Cancer Average Annual Count',
        all_melanoma_of_the_skin_asr_100k:
      'Melanoma of the Skin Cancer Rate per 100k',
        all_melanoma_of_the_skin_average_annual_count:
      'Melanoma of the Skin Cancer Average Annual Count',
        all_non_hodgkin_lymphoma_asr_100k:
      'Non Hodgkin Lymphoma Cancer Age Adjusted Rate per 100k',
        all_non_hodgkin_lymphoma_average_annual_count:
      'Non Hodgkin Lymphoma Cancer Average Annual Count',
        all_oral_cavity_and_pharynx_asr_100k:
      'Oral Cavity Pharynx Cancer Rate per 100k',
        all_oral_cavity_and_pharynx_average_annual_count:
      'Oral Cavity Pharynx Cancer Average Annual Count',
        all_ovary_asr_100k: 'Ovarian Cancer Age Adjusted Rate per 100K',
        all_ovary_average_annual_count: 'Ovarian Cancer Average Annual Count',
        all_pancreas_asr_100k: 'Pancreatic Cancer Rate per 100K',
        all_pancreas_average_annual_count: 'Pancreatic Cancer Average Annual Count',
        all_prostate_asr_100k: 'Prostate Cancer Rate per 100K',
        all_prostate_average_annual_count: 'Prostate Cancer Average Annual Counts',
        all_stomach_asr_100k: 'Stomach Cancer Rate per 100K',
        all_stomach_average_annual_count: 'Stomach Cancer Average Annual Counts',
        all_thyroid_asr_100k: 'Thyroid Cancer Rate per 100K',
        all_thyroid_average_annual_count: 'Thyroid Cancer Average Annual Count',
        all_uterus_corpus_and_uterus_nos_asr_100k: 'Uterine Cancer Rate per 100K',
        all_uterus_corpus_and_uterus_nos_average_annual_count:
      'Uterine Cancer Average Annual Count'
    },
    'Late Stage': {
        latest_bladder_asr_100k: 'Bladder Cancer Rate per 100K',
        latest_bladder_average_annual_count: 'Bladder Cancer Average Annual Count',
        latest_brain_and_ons_asr_100k: 'Brain Cancer Rate per 100K',
        latest_brain_and_ons_average_annual_count:
      'Brain Cancer Average Annual Count',
        // latest_breast_female_in_situ_asr_100k: "Female Breast Cancer in Situ Rate per 100K",
        // latest_breast_female_in_situ_average_annual_count:
        //     "Female Breast Cancer in Situ Average Annual Count",
        latest_breast_female_asr_100k: 'Female Breast Cancer Rate per 100K ',
        latest_breast_female_average_annual_count:
      'Female Breast Cancer Average Annual Count',
        latest_cervix_asr_100k: 'Cervical Cancer Rate per 100K',
        latest_cervix_average_annual_count: 'Cervical Cancer Average Annual Count',
        latest_colon_and_rectum_asr_100k: 'Rectal Colon Cancer Rate per 100K',
        latest_colon_and_rectum_average_annual_count:
      'Rectal Colon Cancer Average Annual Count',
        latest_esophagus_asr_100k: 'Esophageal Cancer Rate per 100K',
        latest_esophagus_average_annual_count:
      'Esophageal Cancer Average Annual Count',
        latest_kidney_and_renal_pelvis_asr_100k:
      'Renal Pelvis Cancer Rate per 100k',
        latest_kidney_and_renal_pelvis_average_annual_count:
      'Renal Pelvis Cancer Average Annual Count',
        // latest_leukemia_asr_100k: "Leukemia Cancer Rate per 100k",
        // latest_leukemia_average_annual_count: "Leukemia Cancer Annual Count",
        latest_liver_and_bile_duct_asr_100k: 'Liver Bile Duct Cancer Rate Per 100k',
        latest_liver_and_bile_duct_average_annual_count:
      'Liver Bile Duct Cancer Average Annual Count',
        latest_lung_and_bronchus_asr_100k: 'Lung Bronchus Cancer Rate per 100k',
        latest_lung_and_bronchus_average_annual_count:
      'Lung Bronchus Cancer Average Annual Count',
        latest_melanoma_of_the_skin_asr_100k:
      'Melanoma of the Skin Cancer Rate per 100k',
        latest_melanoma_of_the_skin_average_annual_count:
      'Melanoma of the Skin Cancer Average Annual Count',
        latest_non_hodgkin_lymphoma_asr_100k:
      'Non Hodgkin Lymphoma Cancer Age Adjusted Rate per 100k',
        latest_non_hodgkin_lymphoma_average_annual_count:
      'Non Hodgkin Lymphoma Cancer Average Annual Count',
        latest_oral_cavity_and_pharynx_asr_100k:
      'Oral Cavity Pharynx Cancer Rate per 100k',
        latest_oral_cavity_and_pharynx_average_annual_count:
      'Oral Cavity Pharynx Cancer Average Annual Count',
        latest_ovary_asr_100k: 'Ovarian Cancer Age Adjusted Rate per 100K',
        latest_ovary_average_annual_count: 'Ovarian Cancer Average Annual Count',
        latest_pancreas_asr_100k: 'Pancreatic Cancer Rate per 100K',
        latest_pancreas_average_annual_count:
      'Pancreatic Cancer Average Annual Count',
        latest_prostate_asr_100k: 'Prostate Cancer Rate per 100K',
        latest_prostate_average_annual_count:
      'Prostate Cancer Average Annual Counts',
        latest_stomach_asr_100k: 'Stomach Cancer Rate per 100K',
        latest_stomach_average_annual_count: 'Stomach Cancer Average Annual Counts',
        latest_thyroid_asr_100k: 'Thyroid Cancer Rate per 100K',
        latest_thyroid_average_annual_count: 'Thyroid Cancer Average Annual Count',
        latest_uterus_corpus_and_uterus_nos_asr_100k:
      'Uterine Cancer Rate per 100K',
        latest_uterus_corpus_and_uterus_nos_average_annual_count:
      'Uterine Cancer Average Annual Count'
    }
};

// api response is Percetage
export const DISEASE_PREVALENCE_STATE_LEVEL = {
    // --- Immunology
    AHRQ_atopic_dermatitis_without_eczema_treated_prevalence_percentage: 'Atopic Dermatitis without Eczema Treated Prevalence Percentage',
    AHRQ_Atopic_Dermatitis_with_Eczema_Treated_Prevalence_Percentage: 'Atopic Dermatitis with Eczema Treated Prevalence Percentage',
    AHRQ_Multiple_Sclerosis_Treated_Prevalence_Percentage: 'Multiple Sclerosis Treated Prevalence Percentage',
    AHRQ_Psoriasis_Treated_Prevalence_Percentage: 'Psoriasis Treated Prevalence Percentage',
    AHRQ_Rheumatoid_Arthritis_Treated_Prevalence_Percentage: 'Rheumatoid Arthritis Treated Prevalence Percentage',
    AHRQ_Systemic_Lupus_Erythematosus_SLE_Treated_Prevalence_Percentage: 'Systemic Lupus Erythematosus (SLE) Treated Prevalence Percentage',
    // --- Cardiovascular
    AHRQ_overweight_reated_revalence_ercentage: 'Overweight Treated Prevalence Percentage',
    // --- Endocrinology
    percent_pop_with_obesity: 'Total Obesity Percentage',
    AHRQ_nonalcoholic_steatohepatitis_nash_treated_prevalence_percentage: 'Non-alcoholic Steatohepatitis (NASH) Treated Prevalence Percentage'
};
