import { CaretUpOutlined, StarFilled } from "@ant-design/icons";

const StatusLegend = () => (
  <div className="status-legends">
    <div className="title">MAP KEY</div>
    <div className="item">
      <StarFilled
        style={{ color: "#00af3f", fontSize: "16px", marginLeft: "-6px" }}
      />{" "}
      <span>SELECTED SITES</span>
    </div>
    <div className="item">
      <CaretUpOutlined
        style={{ color: "#003399", fontSize: "20px", marginLeft: "-8px" }}
      />{" "}
      <span>PURSUED SITES</span>
    </div>
    <div className="item">
      <div className="square-icon" /> <span>CONTACTED SITES</span>
    </div>
  </div>
);

export default StatusLegend;
