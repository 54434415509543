import { GEO_MAP } from '../const/mapping';
import { schemes } from '../pages/MapPage/components/Map/ColorSchemes';


export const getThemeColors = (themeColor:string) => {
    const currentScheme = schemes?.find(s => s.name === themeColor);
    const colors = currentScheme?.colors;
    return colors;
};

const initData = {
    themeColor: 'BuPu',
    colors: getThemeColors('BuPu'),
    previousNickname: null,
    filters: {
        therapeuticArea: null,
        nickname: null,
        demographics: null,
        disease: [],
        prevalenceDisease: [],
        dctElement: [],
        pastTrial: null,
        upcomingTrial: null,
        state: null,
        mapScope: 'US',
        dctCapability: null,
        dctSubCapability: null,
        country: null
    },
    trial: null,
    ta: null,
    pislFilters: null
};

const mapReducer = (state = initData, action: any) => {
    switch (action.type) {
    case GEO_MAP:
        // eslint-disable-next-line no-param-reassign
        state = {
            ...state,
            ...action.data
        };
        break;
    default:
    }
    return state;
};
export default mapReducer;
