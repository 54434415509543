import {
  EventType,
  InteractionRequiredAuthError,
  InteractionType,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";
import { msalInstance } from "./msalInstance";

export const ACCESS_TOKEN_KEY = "accessToken";
export const MS_GRAPH_TOKEN_KEY = "MSGraphToken";

export default async function refreshTokens(
  accounts = msalInstance.getAllAccounts()
): Promise<void | undefined> {
  if (accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    /* There is currently no way to display an error on screen outside of a component. Only console.error here */

    console.error(
      "User is no longer signed in. Please refresh the page to sign in again.",
      "user-not-signed-in"
    );
  }

  const baseRequest:
    | Omit<SilentRequest, "scopes">
    | Omit<RedirectRequest, "scopes"> = {
    account: accounts[0],
    forceRefresh: true,
  };

  msalInstance.addEventCallback(async (event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS &&
      event.interactionType === InteractionType.Redirect &&
      event.payload !== null &&
      ACCESS_TOKEN_KEY in event.payload &&
      event.payload.accessToken !== null
    ) {
      const azureResponse = event.payload;
      if (!azureResponse) throw new Error("No response in acquireAccessToken");

      const msGraphResponse = await msalInstance.acquireTokenSilent({
        ...baseRequest,
        scopes: process.env.REACT_APP_MS_GRAPH_SCOPE?.split(/\s+/) || [],
      });
      localStorage.setItem(ACCESS_TOKEN_KEY, azureResponse.accessToken);
      localStorage.setItem(MS_GRAPH_TOKEN_KEY, msGraphResponse.accessToken);
    }
  });

  try {
    const azureResponse = await msalInstance.acquireTokenSilent({
      ...baseRequest,
      scopes: process.env.REACT_APP_AZURE_SCOPES?.split(/\s+/) || [],
    });
    const msGraphResponse = await msalInstance.acquireTokenSilent({
      ...baseRequest,
      scopes: process.env.REACT_APP_MS_GRAPH_SCOPE?.split(/\s+/) || [],
    });
    localStorage.setItem(ACCESS_TOKEN_KEY, azureResponse.accessToken);
    localStorage.setItem(MS_GRAPH_TOKEN_KEY, msGraphResponse.accessToken);
  } catch (silentAuthError) {
    if (
      silentAuthError instanceof InteractionRequiredAuthError &&
      silentAuthError.name === "InteractionRequiredAuthError"
    ) {
      try {
        await msalInstance.acquireTokenRedirect({
          ...baseRequest,
          scopes: process.env.REACT_APP_AZURE_SCOPES?.split(/\s+/) || [],
        });
      } catch (redirectAuthError) {
        console.error(`Redirect auth failed`, redirectAuthError);
      }
    } else {
      console.error(`Unexpected auth error: ${silentAuthError}`);
    }
  }
  return undefined;
}
