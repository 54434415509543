/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useReducer } from "react";
import { Table } from "antd";
import * as _ from "lodash";
import { unique } from "../../../../utils/getDataUtil";
import { commomColumns, trialColumns, countryColumn } from "./columns";
import useGlobalTableColumns, { dctTypeOptions } from "./useGlobalTableColumns";
import {
  maskDiseaseKey,
  maskDemographicsKey,
  getRange,
  getFilteredData,
} from "./utils";

const NAValue = -1;

interface ChoroplethCountry {
  country: string;
  name: string;
  state: string;
  stateName: string;
  county: string;
  prevalenceFloat: number;
  prevalenceFloat2: number;
  maturityCategory: string;
  maturityCategoryDescription: string;
  comments: string;
}

interface Site {
  country: string;
  state: string;
  county: string;
  name: string;
  facilityId: string;
  city: string;
  maturityCategory: string;
  maturityCategoryDescription: string;
  prevalenceFloat: number;
  prevalenceFloat2: number;
  comments: string;
}

const getTableData = (props: {
  filters: any;
  pastTrialData: any[];
  upcomingTrialData: any[];
  choroplethCountries: ChoroplethCountry[];
}) => {
  const { filters, choroplethCountries, pastTrialData, upcomingTrialData } =
    props;
  let trialData: any[] = pastTrialData.concat(upcomingTrialData);
  trialData = unique(trialData, "facilityId");

  if (
    (filters.pastTrial || filters.upcomingTrial) &&
    filters.prevalenceDisease.length === 0
  ) {
    // only trials
    return trialData;
  }
  if (
    filters.prevalenceDisease.length > 0 &&
    !filters.pastTrial &&
    !filters.upcomingTrial
  ) {
    // only choropleth
    const formatChoroplethData = choroplethCountries.map((country) => ({
      ...country,
      maturityCategory: country.maturityCategory || "N/A",
      prevalenceFloat: country.prevalenceFloat,
      prevalenceFloat2: country.prevalenceFloat2,
      comments: country.comments || "",
    }));
    return formatChoroplethData;
  }
  if (
    (filters.pastTrial || filters.upcomingTrial) &&
    filters.prevalenceDisease.length === 1
  ) {
    // both, choropleth filtered by trials
    const joinedData = trialData.map((trial: any) => {
      const country = choroplethCountries.find(
        (country) => country.country === trial.country
      );
      const item: Site = {
        country: trial?.country || "N/A",
        state: trial?.state || "N/A",
        county: trial?.county || country?.name || "N/A",
        name: trial?.name || "N/A",
        facilityId: trial?.facilityId || "N/A",
        city: trial?.city || "N/A",
        maturityCategory: country?.maturityCategory || "N/A",
        maturityCategoryDescription:
          country?.maturityCategoryDescription || "N/A",
        prevalenceFloat: country?.prevalenceFloat || 0,
        prevalenceFloat2: country?.prevalenceFloat2 || 0,
        comments: country?.comments || "",
      };
      return item;
    });
    return joinedData;
  }
  return choroplethCountries;
};
export interface SiteTableProps {
  filters: any;
  pastTrialData: Array<any>;
  upcomingTrialData: Array<any>;
  choroplethData: Array<any>;
  columns?: Array<any>;
  isPercentage?: boolean;
  updateMap?: any;
  originGlobalData: any[];
}
const SiteTable: React.FC<SiteTableProps> = ({
  filters,
  pastTrialData,
  upcomingTrialData,
  choroplethData,
  originGlobalData,
  updateMap,
}) => {
  let isPercentage: boolean;
  const diseaseKey: string[] = filters.disease?.map((d: any) => d.value);
  const demographicsKey = filters.demographics?.value || "";
  const demographicsText = filters.demographics?.title || "";
  const [columns, setColumns] = useState<any[]>([]);
  const [datasource, setDatasource] = useState<any[]>([]);
  const [originTableData, setOriginTableData] = useState<any[]>([]); // OUS not filtered data
  const [sorterKeys, setSorterKeys] = useState<any[]>([]);
  const [prevalenceValues, setPrevalenceValues] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      prevalenceFloat: [0, 100],
      prevalenceFloat2: [0, 100],
    }
  );
  const [ranges, setRanges] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      prevalenceFloatRange: [0, 100],
      prevalenceFloatRange2: [0, 100],
    }
  );

  const [dctTypes, setDctTypes] = useState<string[]>(dctTypeOptions);
  const [isFiltered, setIsFiltered] = useState(false);
  const maskedDemographicsKey = maskDemographicsKey(demographicsKey);
  const maskedDiseaseKey = diseaseKey?.map((k: string, i: number) => {
    if (filters.disease.length > 1) return `value${i + 1}`;
    return maskDiseaseKey(k);
  });

  const getMultiple = (sortKey: string) => {
    let multiple;
    const idx = sorterKeys.findIndex((e) => e === sortKey);
    if (idx < 0) {
      multiple = 0;
    } else {
      multiple = idx === 0 ? 2 : 1;
    }
    return multiple;
  };

  const demographicsCol: any = {
    title: demographicsText,
    dataIndex: maskedDemographicsKey,
    key: maskedDemographicsKey,
    sorter: {
      compare: (a: any, b: any) =>
        a[maskedDemographicsKey] - b[maskedDemographicsKey],
      multiple: 0,
    },
    render: (text: number) =>
      text === NAValue ? "N/A" : text?.toLocaleString(),
  };

  const diseaseCol: any = maskedDiseaseKey?.map((key: any, idx: number) => ({
    title: filters.disease[idx].title,
    dataIndex: key,
    key,
    sorter: {
      compare: (a: any, b: any) => a[key] - b[key],
      multiple: 0,
    },
    render: (text: number) => {
      const num = text === NAValue ? "N/A" : text?.toLocaleString();
      return num && isPercentage ? `${num}%` : num;
    },
  }));

  const onChange = (pagination: any, filters: any, sorter: any) => {
    let keys: any[] = sorterKeys.slice(0);
    if (Object.prototype.toString.call(sorter) === "[object Object]") {
      keys = [sorter.field];
    }
    if (Object.prototype.toString.call(sorter) === "[object Array]") {
      sorter?.forEach((ele: any) => {
        if (!keys.includes(ele.field)) {
          keys.push(ele.field);
        }
      });
    }
    setSorterKeys(keys);
  };

  const afterSliderChange = (
    key: string,
    value: [number, number],
    originTableData: any[],
    prevalenceValues: any
  ) => {
    setPrevalenceValues({
      [key]: value,
    });
    const filteredResult = getFilteredData({
      datasource: originTableData,
      types: dctTypes,
      prevalenceValues: Object.assign(prevalenceValues, { [key]: value }),
      prevalenceDisease: filters.prevalenceDisease,
    });
    updateMap(filteredResult);
  };

  const onDctTypeChange = (
    values: string[],
    originTableData: any[],
    prevalenceValues: any
  ) => {
    setDctTypes(values);
    const filteredResult = getFilteredData({
      datasource: originTableData,
      types: values,
      prevalenceValues,
      prevalenceDisease: filters.prevalenceDisease,
    });
    updateMap(filteredResult);
  };

  useEffect(() => {
    if (filters.prevalenceDisease.length === 1) {
      const range = getRange(originGlobalData, "prevalenceFloat");
      setPrevalenceValues({
        prevalenceFloat: range,
      });
      setRanges({
        prevalenceFloatRange: range,
      });
    }
    if (filters.prevalenceDisease.length === 2) {
      const range = getRange(originGlobalData, "prevalenceFloat2");
      setRanges({
        prevalenceFloatRange2: range,
      });
      setPrevalenceValues({
        prevalenceFloat2: range,
      });
    }
  }, [originGlobalData, filters.prevalenceDisease]);

  useEffect(() => {
    if (filters.mapScope === "OUS") return;
    let trialData: any[] = pastTrialData.concat(upcomingTrialData);
    trialData = unique(trialData, "facilityId");

    if (
      (filters.pastTrial || filters.upcomingTrial) &&
      !filters.demographics &&
      filters.disease.length === 0
    ) {
      // only trials
      const newCols = commomColumns.concat(trialColumns);
      setColumns(newCols);
      setDatasource(trialData);
    } else if (
      !filters.pastTrial &&
      !filters.upcomingTrial &&
      (filters.demographics || filters.disease.length > 0)
    ) {
      // only choropleth
      const newCols = commomColumns.slice(0);
      if (filters.disease.length > 0) {
        maskedDiseaseKey?.forEach((k: string, i: number) => {
          diseaseCol[i].sorter.multiple = getMultiple(k);
          newCols.push(diseaseCol[i]);
        });
      }
      if (filters.demographics) {
        demographicsCol.sorter.multiple = getMultiple(maskedDemographicsKey);
        newCols.push(demographicsCol);
      }
      setColumns(newCols);

      const newData = choroplethData.map((d) =>
        Object.assign(d, { state: d.stateName, county: d.name })
      );
      setDatasource(newData);
    } else if (
      (filters.pastTrial || filters.upcomingTrial) &&
      (filters.demographics || filters.disease.length > 0)
    ) {
      // both
      const newCols = trialColumns.concat(commomColumns);
      if (filters.disease.length > 1) {
        maskedDiseaseKey?.forEach((k, i) => {
          diseaseCol[i].sorter.multiple = getMultiple(k);
          newCols.push(diseaseCol[i]);
        });
      }
      if (filters.demographics) {
        demographicsCol.sorter.multiple = getMultiple(maskedDemographicsKey);
        newCols.push(demographicsCol);
      }

      // choropleth filtered by trials
      const newData: any = [];
      trialData?.forEach((ele: any) => {
        const target = choroplethData.find(
          (c) => c.stateName === ele.state && c.name === ele.county
        );
        const targetState = choroplethData.find(
          (c) => c.stateName === ele.state
        );
        const item: any = {
          state: ele?.state || target?.stateName || "N/A",
          county: ele?.county || target?.name || "N/A",
          name: ele?.name || "N/A",
          facilityId: ele?.facilityId || "N/A",
          city: ele?.city || "N/A",
        };

        if (filters.disease) {
          maskedDiseaseKey?.forEach((k) => {
            if (target) item[k] = target[k];
            else item[k] = targetState ? targetState[k] : NAValue;
          });
        }
        if (filters.demographics)
          item[maskedDemographicsKey] = target
            ? target[maskedDemographicsKey]
            : NAValue;
        newData.push(item);
      });
      setColumns(newCols);
      setDatasource(newData);
    }
  }, [pastTrialData, upcomingTrialData, choroplethData, sorterKeys]);

  useEffect(() => {
    const initTableData = getTableData({
      filters,
      pastTrialData,
      upcomingTrialData,
      choroplethCountries: originGlobalData,
    });
    setOriginTableData(initTableData);
  }, [originGlobalData, pastTrialData, upcomingTrialData, filters]);

  useEffect(() => {
    if (filters.mapScope === "OUS") {
      const globalColumns: any = useGlobalTableColumns({
        filters,
        sliderValue: prevalenceValues,
        sliderRanges: ranges,
        checkedValues: dctTypes,
        afterSliderChange: (key: string, value: [number, number]) =>
          afterSliderChange(key, value, originTableData, prevalenceValues),
        onSelectChange: (value: string[]) =>
          onDctTypeChange(value, originTableData, prevalenceValues),
      });
      const tableData = getTableData({
        filters,
        pastTrialData,
        upcomingTrialData,
        choroplethCountries: choroplethData,
      });
      setDatasource(tableData);

      const trialCols = countryColumn
        .concat(commomColumns)
        .concat(trialColumns);
      if (
        (filters.pastTrial || filters.upcomingTrial) &&
        filters.prevalenceDisease.length === 0
      ) {
        // only trials
        setColumns(trialCols);
      } else if (
        filters.prevalenceDisease.length > 0 &&
        !filters.pastTrial &&
        !filters.upcomingTrial
      ) {
        // only choropleth
        setColumns(globalColumns);
      } else if (
        (filters.pastTrial || filters.upcomingTrial) &&
        filters.prevalenceDisease.length === 1
      ) {
        // both, choropleth filtered by trials
        let joinedColumns: any[] = countryColumn.concat([
          ...trialColumns,
          ...globalColumns,
        ]);
        joinedColumns = _.uniqBy(joinedColumns, "key");
        setColumns(joinedColumns);
      } else {
        setColumns(globalColumns);
      }
    }
  }, [
    filters.mapScope,
    choroplethData,
    prevalenceValues,
    ranges,
    dctTypes,
    pastTrialData,
    upcomingTrialData,
  ]);

  useEffect(() => {
    if (
      filters.mapScope === "OUS" &&
      dctTypes.length === 5 &&
      prevalenceValues.prevalenceFloat.toString() ===
        ranges.prevalenceFloatRange.toString() &&
      prevalenceValues.prevalenceFloat2.toString() ===
        ranges.prevalenceFloatRange2.toString()
    ) {
      setIsFiltered(false);
    } else {
      setIsFiltered(true);
    }
  }, [filters.mapScope, prevalenceValues, dctTypes, ranges]);

  return (
    <Table
      dataSource={
        filters.mapScope === "US"
          ? datasource
          : !isFiltered
          ? originTableData
          : getFilteredData({
              datasource: originTableData,
              types: dctTypes,
              prevalenceValues,
              prevalenceDisease: filters.prevalenceDisease,
            })
      }
      columns={columns}
      onChange={onChange}
      pagination={{
        pageSize: 5,
        showSizeChanger: false,
        size: "small",
        showTotal: (total: number) => `Total ${total} items`,
      }}
      showSorterTooltip={false}
      rowKey={(record: any) => record.facilityId}
    />
  );
};
export default SiteTable;
